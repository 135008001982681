import axiosInstance from 'service/api';

export default class ProductListService {
    static getProductsList(obj) {
        const endPoint = window.config.genericPost;
        return axiosInstance.post(endPoint, obj);
    }
    static getProductsListByFilters(obj) {
        const endPoint = window.config.genericPost;
        return axiosInstance.post(endPoint, obj);
    }
    static getProductsListBySearch(searchText) {
        const endPoint = `${window.config.genericSearch}?collectionName=${window.config.collections.parts}&search=${searchText}`;
        return axiosInstance.get(endPoint);
    }
}