import { takeEvery, put } from '@redux-saga/core/effects';
import { updatefilterStack, updatefilterStackSuccess, updatefilterStackError, updateDraggablefilterStack,
updateDraggablefilterStackError, updateDraggablefilterStackSuccess } from './filterStack.slice';
import filterStackService from 'service/filterstack.service';

export const updatefilterStackSaga = function* (action) {
    try {
        const res = yield filterStackService.updatefilterStack(action.payload);
        yield put({
            type: updatefilterStackSuccess.type,
            payload: res.data
        });
    } catch (e) {
        yield put({
            type: updatefilterStackError.type,
            payload: "update updatefilterStack action failed"
        });
    }
}

export const updateDraggablefilterStackSaga = function* (action) {
    try {
        const res = yield filterStackService.updateDraggableFilterStack(action.payload);
        yield put({
            type: updateDraggablefilterStackSuccess.type,
            payload: res.data
        });
    } catch (e) {
        yield put({
            type: updateDraggablefilterStackError.type,
            payload: "update updateDraggableFilterStack action failed"
        });
    }
}

export const updatefilterStackWatch = function* () {
    yield takeEvery(updatefilterStack, updatefilterStackSaga);
}

export const updateDraggablefilterStackWatch = function* () {
    yield takeEvery(updateDraggablefilterStack, updateDraggablefilterStackSaga);
}

