import { takeEvery, put } from '@redux-saga/core/effects';
import FilterValueService from 'service/filterValues.service';
import {
    addFilterValues, addFilterValuesSuccess, addFilterValuesError,
    updateFilterValues, updateFilterValuesSuccess, updateFilterValuesError,
    deleteFilterValues, deleteFilterValuesSuccess, deleteFilterValuesError,
    closeFilterValuesModal
} from './filterValues.slice';
import { getInitialCategory } from 'redux/category/category.slice';

export const fetchAddFilterValuesSaga = function* (action) {
    try {
        const res = yield FilterValueService.addFilterValues(action.payload);
        yield put(getInitialCategory());
        yield put(closeFilterValuesModal());
        yield put({
            type: addFilterValuesSuccess.type,
            payload: res.data
        });
    } catch (e) {
        yield put({
            type: addFilterValuesError.type,
            payload: "Add FilterValues action failed"
        });
    }
}

export const fetchUpdateFilterValuesSaga = function* (action) {
    try {
        const res = yield FilterValueService.updateFilterValues(action.payload);
        yield put(getInitialCategory());
        yield put(closeFilterValuesModal());
        yield put({
            type: updateFilterValuesSuccess.type,
            payload: res.data
        });
    } catch (e) {
        yield put({
            type: updateFilterValuesError.type,
            payload: "Update FilterValues action failed"
        });
    }
}

export const fetchDeleteFilterValuesSaga = function* (action) {
    try {
        const res = yield FilterValueService.deleteFilterValues(action.payload);
        yield put(getInitialCategory());
        yield put(closeFilterValuesModal());
        yield put({
            type: deleteFilterValuesSuccess.type,
            payload: res.data
        });
    } catch (e) {
        yield put({
            type: deleteFilterValuesError.type,
            payload: "Delete action failed"
        });
    }
}

export const fetchAddFilterValuesWatch = function* () {
    yield takeEvery(addFilterValues, fetchAddFilterValuesSaga);
}

export const fetchUpdateFilterValuesWatch = function* () {
    yield takeEvery(updateFilterValues, fetchUpdateFilterValuesSaga);
}

export const fetchDeleteFilterValuesWatch = function* () {
    yield takeEvery(deleteFilterValues, fetchDeleteFilterValuesSaga);
}