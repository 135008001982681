import { createTheme } from '@mui/material/styles';
import { pink, grey, red } from '@mui/material/colors'

const SankarTheme = createTheme({
  palette: {
    primary: red,
    secondary: pink,
    text: {
      primary: 'red',
      secondary: grey[700],
      name: 'first'
    },
    signup: {
      buttonColor: 'red !important',
      subcaptchaColor:'white',
      captchaInputRoot:'#ffffff',
      formLabelColor:'white !important',
      formBg:'#f5f5f5',
      inputRootBgColor:'#ffffff',
      selectBgColor:'#ffffff',
      cityBgColor:'#ffffff',
      btnSignupColor:'white !important',
      dialogBgColor:'red !important',
      closeIconColor:'white !important',
      fontControlColor:'red !important'
    },
    login:{
      captcha:'#ffffff',
      subCaptcha:'white',
      input:'#ffffff',
      link:'red !important',
      submitButtonColor:'red !important'
    },
    forgotpassword: {
      buttonColor:'red',
      dividerLabelColor:'red',
      link:'red',
      submitButtonColor:'red !important'
    },
    adminforgotpassword:{
      formContainerbgColor:'#fafbfc',
      color:'red',
      dividerColor:"1px solid red"
    },
    resetpassword: {
      buttonColor:'red',
      dividerLabelColor:'red',
      link:'red !important',
      submitButtonColor:'red !important',
      border:'solid thin red',
      color:'red'
    },
    addUser:{
      containerBgColor:'red',
      dropdownControlColor:'black',
      dialogTitleBgColor:'red',
      dialogTitleColor:'white',
      muiButtonBgColor:'red !important'
   },
   adminLogin:{
    formContainerBgColor:'#fafbfc'
   },
   admin:{
     miniDrawerBg:'red',
     menuIconColor: 'red !important',
     activeColor: 'red'
   },

   partDetails: {
    columnHeader: 'red'
  },
  partImages: {
    columnHeader: 'red'
  },
  partFilter: {
    columnHeader: 'red'
  },
  oeNumber: {
    columnHeader: 'red'
  },
  subParts: {
    columnHeader: 'red'
  },
  catalogSetting: {
    bgColor: 'red',
    lightBgColor: '#FFCCCB',
    border:'2px solid red'
  },
  productPageList:{
    columnHeader: 'red'
  },
  checkbox: {
    color: 'red !important'
  },
  groupList:{
    columnHeader: 'red'
  },
  filterLabel:{
    columnHeader: 'red'
  },
  filterValues:{
    columnHeader: 'red'
  },
  specification:{
    columnHeader: 'red'
  },
  

    catalogItem:{
      itemCard:'#fff',
      itemCardHover:'#ff0000',
      itemCardHoverBorder:'1px solid red',
      itemNameColor:'#fff',
      itemNameBackgroundColor:'#000',
      productCardFooterHoverColor:'white',
      itemCardBgColor:'#f9f9f9',
      itemCardColor:'black',
      itemCardHoverColor:'white'
    },
    filterElement:{
        checked:'red !important',
        filterSelectBackgroundColor:'#363636',
        filterSelectColor:'#fff'
    },
    Header:{
      navbarBackgroundColor:'rgba(0,0,0,.9)',
      menuItem:'#ffffff',
      menuItemHover:'red',
      menuChildBackgroundColor:'#000000d1',
      searchInput:'#495057',
      searchInputBorder:'1px solid red'
    },
    sideBar:{
      filterHeaderBackground:'#ff3300',
      filterHeaderColor:'#fff',
      filterHeaderBorderTop:'red',
      filterHeaderBefore:'rgba(255, 51, 0,0.09)  !important',
      filterContentHeaderText:'red',
      filterContentHighlightBg:'#dae7e8 !important',
      filterContentHighlightColor:'black !important',
      filterContentWrapper:'white',
      filterContentScrollThumb:'red',
    },
    productDescription:{
      breadCrumb:'#ff3300',
      gridRowBorder:'1px solid #eaeaea',
      gridColumnFirstTypeBorder:'solid #eaeaea',
      gridColumnLastTypeBorder:'1px solid #eaeaea',
      gridColumnItemWrapperBorder:'1px solid #dedddd',
      gridColumnItemImgBorder:'1px solid #eaeaea',
      gridColumnItemContainerBorder:'1px solid #eaeaea',
      gridColumnItemContentBorder:'1px solid black',
      gridColumnHeaderHeighlightBg:'#ff3300 !important',
      gridColumnHeaderHeighlightColor:'white !important',
      gridColumnHeaderHeighlightHoverColor:'white !important',
      gridColumnHeaderHeighlightHoverBg:'#ff3300 !important',
      gridColumnHeaderLinkBg:'white',
      gridColumnHeaderLinkColor:'#495057',
      gridColumnHeaderLinkBorder:'1px solid #dee2e6',
      gridColumnHeaderLinkBackground:'#f8f9fa',
      gridColumnTableDataColor:'#000',
      gridColumnTableDataBorder:'0.1px solid black',
      gridColumnTableHeadBorder:'0.1px solid black',
      gridColumnTableHeadBackground:'red',
      gridColumnTableHeadColor:'white',
      gridColumnTableContentBorder:'0.1px solid black !important',
      gridColumnThumbPrevNext:'black !important',
      gridColumnTableDataFirstChild:'none',
      gridColumnTableDataLastChild:'none',
      specificationTableTableRow:'none',
      gridBtnBg:'red',
      gridBtnColor:'white',
      btnSearchColor:'white',
      btnSearchBg:'red',
      serachInputBorder:'1px solid #ff3300',
      InnerImageZoomContainerBg:'red',
      imageCardHoverBorderColor:'2px solid red',
      onClickCloseBg:'red !important'
    },
    productList:{
      productCardHeader:'#ff0000',
      productCardFooter:'#f9f9f9',
      productCardFooterColor:'black',
      breadCrumb:'red',
      mainContainerDataViewHeaderBg:'#fff',
      mainContainerDataViewHeaderColor:'#495057',
      mainContainerDataViewHeaderBorder:'1px solid #e9ecef',
      mainContainerDataViewContentColor:'#000000',
      mainContainerDataViewContentBg:'#f1f6f8',
      headerGridHighlightBorder:'red',
      headerGridHighlightBackground:'red',
      headerGridHighlightHoverBorder:'red',
      headerGridHighlightHoverBackground:'red',
      shareBtnBackground:'transparent',
      shareBtnColor:'red',
      shareIconColor:'red',
      productDescriptionBg:'#000000',
      productDescriptionColor:'#ffffff',
      productNameBg:'#000',
      productNameColor:'#fff',
      productListFooterHeight: '71px',
      productListfooterDisplay: 'flex',
      productListTitleDisplay: 'block',
      productListTitleHeight: 'auto',
      productListItemBackground:'#dae7e8',
      productListDetailBorder:'1px solid #fff',
      productListDetailBackground:'#fff',
      viewMoreLinkColor:'white',
      viewMoreLinkBackground:'red !important',
      viewMoreLinkBorder:'none',
      gridColumnTableDataColor:'black',
      gridColumnTableDataBorder:'0.1px solid black',
      gridColumnTableDataFirstChild:'0.1px solid black',
    },
    BreadCrumb:{
      textParagraphColor:'#ffffff',
      linkStyle:'#ffffff',
      breadCrumbColor:'#ffffff'
    },
    Loader:{
      loaderContainerBgColor:'rgba(0,0,0,0.2)'
    },
    emailVerify:{
      btnStyle:'red !important'
    },
    Header:{
      navBarBgColor:'#ff3300',
      menuContainerBgColor:'#ff3300',
      menuContainerColor:'white',
      menuIconBgColor:'white',
      menuItemColor:'#ffffff',
      menuChildBackground:'#ffffff',
      menuChildColor:'#84898e !important',
      mobileMenuChildContainerBackground:'#ffffff !important',
      menuChildContainerBackground:'#f5f5f5',
      searchInputBorder:'1px solid red',
      searchInputColor:'#495057',
      searchButtonBorder:'1px solid red',
      searchButtonBgColor:'#fff'
    },
    toggleImg:{
      mobileFilterLogo:'red'
    },
    toggleSearchIcon:{
      toggleSearchIconColor:'1px solid red'
    },
    SocialShare:{
      messagePositionBgColor:'red'
    },
    background: {
      default: '#eee',
      paper: '#fff',
    },
    divider: grey[300],
  },
  spacing: 8,
  typography: {
    fontFamily: [
      '"Hiragino Sans"',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      'Helvetica',
      'Arial',
      'sans-serif',
    ].join(','),
  },
  components: {
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {},
    },
    MuiTextField: {
      defaultProps: {
        variant: 'filled',
      },
    },
  },
});

export default SankarTheme;
