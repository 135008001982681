import { makeStyles, createStyles } from "@mui/styles";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => {
    return createStyles({
        profileColor: {
            backgroundColor: ({ colorState }) => colorState,
            textAlign: 'center',
            padding: '5px',
            color: 'white',
            borderRadius: '5px',
            border: '1px solid grey'
        },
        listItem: {
            cursor: 'pointer'
        }
    })
})

export default function ProfileIcon() {
    const { organizationDetail } = useSelector((state) => state);
    const colorData = process.env.REACT_APP_PROJECT_NAME || window.config.tenant == 'amrep' ? "#007a49" : process.env.REACT_APP_PROJECT_NAME || window.config.tenant == 'demo' ? "#e0cc54" : "";
    const colorState = organizationDetail.data.color ? organizationDetail.data.color : colorData;
    const classes = useStyles({colorState});
    // const classes = useStyles();

    return (
        <div className={classes.profileColor}>
            <span className={classes.listItem}>My Profile</span>
            <hr></hr>
            <div onClick={() => {
                localStorage.removeItem('adminuser');
                window.location.reload();
            }}><span className={classes.listItem}>Logout</span>
            </div>
        </div>
    );
}

