import axiosInstance from 'service/api';
import Constant from 'common/constant';

export default class ProductPageListService {

    static getProductPageList() {
        const endPoint = `${window.config.genericGet}?collectionName=${window.config['collections']['masterLookup']}&search={'lookup_id':'${Constant.masterLookup.config.name}'}`;
        return axiosInstance.get(endPoint);
    }

    static updateProductPage(values) {
        const body = {
            collectionName: 'master_lookups',
            lookup_id: "Config",
            data: [values],
            condition: [{_id: values._id}]
        }
        const endPoint = `${window.config.updateProductPage}`;
        return axiosInstance.post(endPoint, body);
    }

}