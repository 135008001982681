import axiosInstance from 'service/api';
import Constant from 'common/constant';

export default class CategoriesService {

    static addFilterValues(values) {
        const endPoint = `${window.config.addFilterValues}`;
        return axiosInstance.post(endPoint, values);
    }

    static updateFilterValues(values) {
        const endPoint = `${window.config.updateFilterValues}`;
        return axiosInstance.post(endPoint, values);
    }

    static deleteFilterValues(values) {
        const endPoint = `${window.config.deleteFilterValues}`;
        return axiosInstance.post(endPoint, values);
    }
}
