import axiosInstance from 'service/api';

export default class PartDetailsService {
    static getPartList() {
        const endPoint = `${window.config.genericGet}?collectionName=${window.config['collections']['parts']}&search={}`;
        return axiosInstance.get(endPoint);
    }
    static addPart(values) {
        const body = {
            collectionName: 'parts',
            data: [values]
        }
        const endPoint = `${window.config.insertPartDocument}`;
        return axiosInstance.post(endPoint, body);
    }
    static updatePart(values) {
        const params = {...values};
        console.log(params);
        delete params._id;
        const body = {
            collectionName: 'parts',
            data: [params],
            condition: [{_id: values._id}]
        }
        const endPoint = `${window.config.updateSinglePartDocument}`;
        return axiosInstance.post(endPoint, body);
    }
    static deletePart(values) {
        const body = {
            collectionName: 'parts',
            delete: [{_id: values._id}],
            
        }
        const endPoint = `${window.config.deleteSinglePartDocument}`;
        return axiosInstance.post(endPoint, body);
    }
    static getProvider() {
        const endPoint = `${window.config.genericGet}?collectionName=${window.config.provider}&search={}`;
        return axiosInstance.get(endPoint);
    }
    
}