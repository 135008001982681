import { takeEvery, put } from '@redux-saga/core/effects';
import PartFiltersService from 'service/partFilters.service';
import BulkUploadService from 'service/bulkUpload.service';
import { getTemplate, getTemplateSuccess, getTemplateFailure,
    updateTemplateData, updateTemplateDataSuccess, updateTemplateDataFailure,
    bulkUploadFiles, bulkUploadFailure, bulkUploadSuccess } from './bulkupload.slice';

export const fetchPartTemplateSaga = function* () {
    try {
        const res = yield BulkUploadService.getTemplate();
        yield put({
            type: getTemplateSuccess.type,
            payload: res
        });
    } catch (e) {
        yield put({
            type: getTemplateFailure.type,
            payload: "Partlist failed to fetch"
        });
    }
}

export const fetchPartTemplateWatch = function* () {
    yield takeEvery(getTemplate, fetchPartTemplateSaga);
}
export const fetchBulkUploadSaga = function* (action) {
    try {
        const res = yield BulkUploadService.bulkUpload(action.payload);
        yield put({
            type: bulkUploadSuccess.type,
            payload: res
        });
    } catch (e) {
        yield put({
            type: bulkUploadFailure.type,
            payload: "Bulk upload failed"
        });
    }
}

export const fetchBulkUploadWatch = function* () {
    yield takeEvery(bulkUploadFiles, fetchBulkUploadSaga);
}