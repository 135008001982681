import { takeEvery, put } from '@redux-saga/core/effects';
import IndependentFilterService from 'service/independentFilter.service';
import {
    addIndependentFilter, addIndependentFilterSuccess, addIndependentFilterError,
    updateIndependentFilter, updateIndependentFilterError, updateIndependentFilterSuccess,
    deleteIndependentFilter, deleteIndependentFilterSuccess, deleteIndependentFilterError,
    closeIndependentFilterModal, 

} from './IndependentFilter.slice';
import { getInitialCategory } from 'redux/category/category.slice';

export const fetchAddIndependentFilterSaga = function* (action) {
    try {
        const res = yield IndependentFilterService.addIndependentFilter(action.payload);
        yield put(getInitialCategory());
        yield put(closeIndependentFilterModal());
        yield put({
            type: addIndependentFilterSuccess.type,
            payload: res.data
        });
    } catch (e) {
        yield put({
            type: addIndependentFilterError.type,
            payload: "Add IndependentFilter action failed"
        });
    }
}

export const fetchUpdateIndependentFilterSaga = function* (action) {
    try {
        const res = yield IndependentFilterService.updateIndependentFilter(action.payload);
        yield put(getInitialCategory());
        yield put(closeIndependentFilterModal());
        yield put({
            type: updateIndependentFilterSuccess.type,
            payload: res.data
        });
    } catch (e) {
        yield put({
            type: updateIndependentFilterError.type,
            payload: "Update IndependentFilter action failed"
        });
    }
}

export const fetchDeleteIndependentFilterSaga = function* (action) {
    try {
        const res = yield IndependentFilterService.deleteIndependentFilter(action.payload);
        yield put(getInitialCategory());
        yield put(closeIndependentFilterModal());
        yield put({
            type: deleteIndependentFilterSuccess.type,
            payload: res.data
        });
    } catch (e) {
        yield put({
            type: deleteIndependentFilterError.type,
            payload: "Delete IndependentFilter action failed"
        });
    }
}

export const fetchAddIndependentFilterWatch = function* () {
    yield takeEvery(addIndependentFilter, fetchAddIndependentFilterSaga);
}

export const fetchUpdateIndependentFilterWatch = function* () {
    yield takeEvery(updateIndependentFilter, fetchUpdateIndependentFilterSaga);
}

export const fetchDeleteIndependentFilterWatch = function* () {
    yield takeEvery(deleteIndependentFilter, fetchDeleteIndependentFilterSaga);
}