import { createTheme } from '@mui/material/styles';
import { pink, grey, yellow } from '@mui/material/colors'

const DemoTheme = createTheme({
  palette: {
    primary: yellow,
    secondary: pink,
    text: {
      primary: '#e2cc48',
      secondary: grey[700],
      name: 'second',
    },
    signup: {
      buttonColor: '#e2cc48 !important',
      subcaptchaColor: 'white',
      captchaInputRoot: '#ffffff',
      formLabelColor: 'white !important',
      formBg: '#f5f5f5',
      inputRootBgColor: '#ffffff',
      selectBgColor: '#ffffff',
      cityBgColor: '#ffffff',
      btnSignupColor: 'white !important',
      dialogBgColor: '#e2cc48 !important',
      closeIconColor: 'white !important',
      fontControlColor: '#e2cc48 !important'
    },
    login: {
      captcha: '#ffffff',
      subCaptcha: 'white',
      input: '#ffffff',
      link: '#e2cc48 !important',
      submitButtonColor: '#e2cc48 !important'
    },
    forgotpassword: {
      buttonColor: '#e2cc48',
      dividerLabelColor: '#e2cc48',
      link: '#e2cc48',
      submitButtonColor: '#e2cc48 !important'
    },
    adminforgotpassword: {
      formContainerbgColor: '#fafbfc',
      color: '#e2cc48',
      dividerColor: "1px solid #e2cc48"
    },
    resetpassword: {
      buttonColor: '#e2cc48',
      dividerLabelColor: '#e2cc48',
      link: '#e2cc48 !important',
      submitButtonColor: '#e2cc48 !important',
      border: 'solid thin #e2cc48',
      color: '#e2cc48'
    },
    addUser: {
      containerBgColor: '#e2cc48',
      dropdownControlColor: 'black',
      dialogTitleBgColor: '#e2cc48',
      dialogTitleColor: 'white',
      muiButtonBgColor: '#e2cc48 !important'
    },
    adminLogin: {
      formContainerBgColor: '#fafbfc'
    },
    admin: {
      miniDrawerBg: '#e2cc48',
      menuIconColor: '#e2cc48 !important',
      activeColor: '#e2cc48'
    },
    partDetails: {
      columnHeader: '#e2cc48'
    },
    partImages: {
      columnHeader: '#e2cc48'
    },
    partFilter: {
      columnHeader: '#e2cc48'
    },
    oeNumber: {
      columnHeader: '#e2cc48'
    },
    subParts: {
      columnHeader: '#e2cc48'
    },
    catalogSetting: {
      bgColor: '#e2cc48',
      lightBgColor: '#FFFFE0',
      border: '2px solid #e2cc48'
    },
    productPageList: {
      columnHeader: '#e2cc48'
    },
    groupList: {
      columnHeader: '#e2cc48'
    },
    filterLabel: {
      columnHeader: '#e2cc48'
    },
    filterValues: {
      columnHeader: '#e2cc48'
    },
    specification: {
      columnHeader: '#e2cc48'
    },
    catalogItem: {
      itemCard: '#fff',
      itemCardHover: '#e2cc48',
      itemCardHoverBorder: 'none',
      itemNameColor: '#fff',
      itemNameBackgroundColor: '#000',
      productCardFooterHoverColor: 'none',
      itemCardBgColor: 'none',
      itemCardColor: 'none',
      itemCardHoverColor: 'none'
    },
    checkbox: {
      color: '#e2cc48 !important'
    },
    filterElement: {
      checked: '#e2cc48 !important',
      filterSelectBackgroundColor: '#363636',
      filterSelectColor: '#fff'
    },
    Header: {
      navbarBackgroundColor: 'rgba(0,0,0,.9)',
      menuItem: '#ffffff',
      menuItemHover: '#e2cc48',
      menuChildBackgroundColor: '#000000d1',
      searchInput: '#495057',
      searchInputBorder: '1px solid #e2cc48'
    },
    sideBar: {
      filterHeaderBackground: '#e2cc48',
      filterHeaderColor: '#fff',
      filterHeaderBorderTop: '#e2cc48',
      filterHeaderBefore: 'rgba(255, 51, 0,0.09)  !important',
      filterContentHeaderText: '#e2cc48',
      filterContentHighlightBg: '#dae7e8 !important',
      filterContentHighlightColor: 'black !important',
      filterContentWrapper: 'white',
      filterContentScrollThumb: '#e2cc48',
    },
    productDescription: {
      breadCrumb: '#e2cc48',
      gridRowBorder: '1px solid #eaeaea',
      gridColumnFirstTypeBorder: 'solid #eaeaea',
      gridColumnLastTypeBorder: '1px solid #eaeaea',
      gridColumnItemWrapperBorder: '1px solid #dedddd',
      gridColumnItemImgBorder: '1px solid #eaeaea',
      gridColumnItemContainerBorder: '1px solid #eaeaea',
      gridColumnItemContentBorder: '1px solid black',
      gridColumnHeaderHeighlightBg: '#e2cc48 !important',
      gridColumnHeaderHeighlightColor: 'white !important',
      gridColumnHeaderHeighlightHoverColor: 'white !important',
      gridColumnHeaderHeighlightHoverBg: 'black !important',
      gridColumnHeaderLinkBg: 'white',
      gridColumnHeaderLinkColor: '#495057',
      gridColumnHeaderLinkBorder: '1px solid #dee2e6',
      gridColumnHeaderLinkBackground: '#f8f9fa',
      gridColumnTableDataColor: '#000',
      gridColumnTableDataBorder: '0.1px solid black',
      gridColumnTableHeadBorder: '0.1px solid black',
      gridColumnTableHeadBackground: '#e2cc48',
      gridColumnTableHeadColor: 'white',
      gridColumnTableContentBorder: '0.1px solid black !important',
      gridColumnThumbPrevNext: 'black !important',
      gridColumnTableDataFirstChild: 'none',
      gridColumnTableDataLastChild: 'none',
      specificationTableTableRow: 'none',
      gridBtnBg: '#ffc107',
      gridBtnColor: 'white',
      btnSearchColor: 'white',
      btnSearchBg: '#e2cc48',
      serachInputBorder: '1px solid #ff3300',
      InnerImageZoomContainerBg: '#e2cc48',
      imageCardHoverBorderColor: '2px solid #e2cc48',
      onClickCloseBg: '#e2cc48 !important'
    },
    productList: {
      productCardHeader: 'none',
      productCardFooter: 'none',
      productCardFooterColor: 'none',
      breadCrumb: '#e2cc48',
      mainContainerDataViewHeaderBg: '#fff',
      mainContainerDataViewHeaderColor: '#495057',
      mainContainerDataViewHeaderBorder: '1px solid #e9ecef',
      mainContainerDataViewContentColor: '#000000',
      mainContainerDataViewContentBg: '#f1f6f8',
      headerGridHighlightBorder: '#e2cc48',
      headerGridHighlightBackground: '#e2cc48',
      headerGridHighlightHoverBorder: '#e2cc48',
      headerGridHighlightHoverBackground: '#e2cc48',
      shareBtnBackground: 'transparent',
      shareBtnColor: '#e2cc48',
      shareIconColor: '#e2cc48',
      productDescriptionBg: '#000000',
      productDescriptionColor: '#ffffff',
      productNameBg: '#000',
      productNameColor: '#fff',
      productListFooterHeight: '71px',
      productListfooterDisplay: 'flex',
      productListTitleDisplay: 'block',
      productListTitleHeight: 'auto',
      productListItemBackground: '#dae7e8',
      productListDetailBorder: '1px solid #fff',
      productListDetailBackground: '#fff',
      viewMoreLinkColor: 'white',
      viewMoreLinkBackground: '#000 !important',
      viewMoreLinkBorder: '1px solid #000 !important',
      gridColumnTableDataColor: 'black',
      gridColumnTableDataBorder: '0.1px solid black',
      gridColumnTableDataFirstChild: '0.1px solid black',
    },
    BreadCrumb: {
      textParagraphColor: '#ffffff',
      linkStyle: '#ffffff',
      breadCrumbColor: '#ffffff'
    },
    Loader: {
      loaderContainerBgColor: 'rgba(0,0,0,0.2)'
    },
    emailVerify: {
      btnStyle: '#e2cc48 !important'
    },
    Header: {
      navBarBgColor: 'rgba(0,0,0,.9)',
      menuContainerBgColor: 'rgba(0,0,0,.9)',
      menuContainerColor: 'white',
      menuIconBgColor: 'white',
      menuItemColor: '#ffffff',
      menuItemHoverColor: '#e2cc48',
      menuChildBackground: '#000000d1',
      mobileMenuChildContainerBackground: '#ffffff !important',
      menuChildContainerBackground: '#f5f5f5',
      searchInputBorder: '1px solid #e2cc48',
      searchInputColor: '#495057',
      searchButtonBorder: '1px solid #e2cc48',
      searchButtonBgColor: '#fff'
    },
    toggleImg: {
      mobileFilterLogo: '#e2cc48'
    },
    toggleSearchIcon: {
      toggleSearchIconColor: '1px solid #e2cc48'
    },
    SocialShare: {
      messagePositionBgColor: '#e2cc48'
    },
    background: {
      default: '#eee',
      paper: '#fff',
    },
    divider: grey[300],
  },
  spacing: 8,
  typography: {
    fontFamily: [
      '"Hiragino Sans"',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      'Helvetica',
      'Arial',
      'sans-serif',
    ].join(','),
  },
  components: {
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {},
    },
    MuiTextField: {
      defaultProps: {
        variant: 'filled',
      },
    },
  },
});

export default DemoTheme;
