import { createSlice } from '@reduxjs/toolkit';
import { ApiStatus } from 'common/constant';

export const initialOrganizationState = {
    data: {},
    status: ''
};

export const organizationSlice = createSlice({
  name: 'organizationSlice',
  initialState: initialOrganizationState,
  reducers: {
    getOrganization: (state) => {
      state.status = ApiStatus.loading;
    },
    getOrganizationSuccess: (state, action) => {
        state.status = ApiStatus.success;
        state.data = action.payload.length ? action.payload[0]: action.payload;
    },
    getOrganizationError: (state, action) => {
        state.status = ApiStatus.error;
        state.data = action.payload;
    },
  }
});

export const { getOrganization, getOrganizationSuccess, getOrganizationError } = organizationSlice.actions;

export default organizationSlice.reducer;
