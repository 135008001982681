import { takeEvery, put } from '@redux-saga/core/effects';
import IndependentFilterService from 'service/independentFilter.service';
import {
    
    addIndependentFilterLabel,addIndependentFilterLabelSuccess,addIndependentFilterLabelError,
    updateIndependentFilterLabel,updateIndependentFilterLabelSuccess,updateIndependentFilterLabelError,
    deleteIndependentFilterLabel, deleteIndependentFilterLabelSuccess, deleteIndependentFilterLabelError,
    closeIndependentFilterLabelModal

} from './IndependentFilterLabel.slice';
import { getInitialCategory } from 'redux/category/category.slice';

export const fetchAddIndependentFilterLabelSaga = function* (action) {
    try {
        const res = yield IndependentFilterService.addIndependentFilterLabel(action.payload);
        yield put(getInitialCategory());
        yield put(closeIndependentFilterLabelModal());
        yield put({
            type: addIndependentFilterLabelSuccess.type,
            payload: res.data
        });
    } catch (e) {
        yield put({
            type: addIndependentFilterLabelError.type,
            payload: "Add IndependentFilterLabel action failed"
        });
    }
}

export const fetchUpdateIndependentFilterLabelSaga = function* (action) {
    try {
        const res = yield IndependentFilterService.updateIndependentFilterLabel(action.payload);
        yield put(getInitialCategory());
        yield put(closeIndependentFilterLabelModal());
        yield put({
            type: updateIndependentFilterLabelSuccess.type,
            payload: res.data
        });
    } catch (e) {
        yield put({
            type: updateIndependentFilterLabelError.type,
            payload: "Update IndependentFilterLabel action failed"
        });
    }
}

export const fetchDeleteIndependentFilterLabelSaga = function* (action) {
    try {
        const res = yield IndependentFilterService.deleteIndependentFilterLabel(action.payload);
        yield put(getInitialCategory());
        yield put(closeIndependentFilterLabelModal());
        yield put({
            type: deleteIndependentFilterLabelSuccess.type,
            payload: res.data
        });
    } catch (e) {
        yield put({
            type: deleteIndependentFilterLabelError.type,
            payload: "Delete IndependentFilterLabel action failed"
        });
    }
}

export const fetchAddIndependentFilterLabelWatch = function* () {
    yield takeEvery(addIndependentFilterLabel, fetchAddIndependentFilterLabelSaga);
}

export const fetchUpdateIndependentFilterLabelWatch = function* () {
    yield takeEvery(updateIndependentFilterLabel, fetchUpdateIndependentFilterLabelSaga);
}

export const fetchDeleteIndependentFilterLabelWatch = function* () {
    yield takeEvery(deleteIndependentFilterLabel, fetchDeleteIndependentFilterLabelSaga);
}