import axiosInstance from 'service/api';

export default class AuthenticationService {
    static login(values) {
        const endPoint = window.config.authentication;
        return axiosInstance.post(endPoint, values);
    }
    static signup(values){
        const endPoint = window.config.register;
        return axiosInstance.post(endPoint, values);
    }
    static emailverification(values){
        const endPoint = window.config.sendEmailLink;
        return axiosInstance.post(endPoint, values);
    }
    static emailverify(values){
        const endPoint = window.config.verify;
        return axiosInstance.post(endPoint, values);
    }
    static getCountries(){
        const endPoint = `${window.config.genericGet}?collectionName=${window.config.countryState}&search={}`;
        return axiosInstance.get(endPoint);
    }
    static getIntrestedAs(){
        const endPoint =`${window.config.genericGet}?collectionName=${window.config.intrestedAs}&search={}`;
        return axiosInstance.get(endPoint);
    }
    static forgotPassword(data){
        const endPoint = window.config.ForgotPassword;
        return axiosInstance.post(endPoint, data);
    }
}
