import axiosInstance from 'service/api';

export default class IndependentFilterService {

    static addIndependentFilter(values) {
        const { info, filter } = values;
        const data = {
            "collectionName" : "master_lookups",
            "filter" : {
                "_id" : filter._id
            },
            "data" : [{
                "name" : info[0].name, 
                "img" : info[0].img
            }]
        }
        const endPoint = `${window.config.insertIndependentFilterInMasterLookups}`;
        return axiosInstance.post(endPoint, data);
    }

    static updateIndependentFilter(values) {
        const { filter, info } = values;
        const data = {
            "collectionName" : "master_lookups",
            "filter" : {
                "id" : filter["_id"]
            },
            "content" : [{
                "_id" : info[0]["_id"],
                "name" : info[0]["name"], 
                "img" : info[0]["img"]
            }]
        }
        const endPoint = `${window.config.updateIndependentFilterMaterial}`;
        return axiosInstance.post(endPoint, data);
    }

    static deleteIndependentFilter(values) {
        const { filter, info } = values;
        const data = {
            "collectionName" : "master_lookups",
            "filter" : {
                "id" : filter["_id"]
            },
            "content" : [{
                "_id" : info[0]["_id"]
            }]
        }
        console.log(data);
        const endPoint = `${window.config.deleteIndependentFilterMaterial}`;
        return axiosInstance.post(endPoint, data);
    }

    static addIndependentFilterLabel(values) {
       
        const data = {
            "collectionName": "organization",
            "data": [{
                "id": values.id,
                "name": values.name
            }]
        }
        const endPoint = `${window.config.insertIndependentFilterLabel}`;
        return axiosInstance.post(endPoint, data);
    }

    static updateIndependentFilterLabel(values) {
        console.log(values);

        const data = {
            "collectionName" : "organization",
            "filter" : {
                "mlid" : values.Mlid,
                "orgId" : values.OrgId
            },
            "content" : {
                "id" : values.id, 
                "name" : values.name
            }
        }
        const endPoint = `${window.config.updateIndependentFilterLabel}`;
        return axiosInstance.post(endPoint, data);
    }

    static deleteIndependentFilterLabel(values) {
        const { deleteId, inx} = values;
        const data =  {
            "collectionName": "organization",
            "content": {
                "id": inx.id,
                "deleteId" : deleteId
            }    
        }
        console.log(data);
        const endPoint = `${window.config.deleteIndependentFilterLabel}`;
        return axiosInstance.post(endPoint, data);
    }
}
