import { createSlice } from '@reduxjs/toolkit';
import { ApiStatus } from 'common/constant';

export const initialSpecificationListState = {
    list: {},
    status: '',
    addSpecificationInfo: {
        status: '',
        data: {}
    },
    addSpecificationModal: {
        isDialogOpen: false,
        mode: '',
        data: null
    },
    updateSpecificationInfo: {
        status: ApiStatus.loading,
        data: ''
    },
    deleteSpecificationInfo: {
        status: ApiStatus.loading,
        data: {}
    },
};

export const specificationSlice = createSlice({
    name: 'specificationSlice',
    initialState: initialSpecificationListState,
    reducers: {
        getSpecificationList: (state) => {
            state.status = ApiStatus.loading;
        },
        getSpecificationListSuccess: (state, action) => {
            state.status = ApiStatus.success;
            state.list = action.payload[0];
        },
        getSpecificationListError: (state, action) => {
            state.status = ApiStatus.error;
            state.list = action.payload;
        },
        addSpecification: (state) => {
            state.addSpecificationInfo.status = ApiStatus.loading;
        },
        addSpecificationSuccess: (state, action) => {
            state.addSpecificationInfo.status = ApiStatus.success;
            state.addSpecificationInfo.data = action.payload;
        },
        addSpecificationError: (state, action) => {
            state.addSpecificationInfo.status = ApiStatus.error;
            state.addSpecificationInfo.data = action.payload;
        },
        updateSpecification: (state) => {
            state.updateSpecificationInfo.status = ApiStatus.loading;
        },
        updateSpecificationSuccess: (state, action) => {
            state.updateSpecificationInfo.status = ApiStatus.success;
            state.updateSpecificationInfo.data = action.payload;
        },
        updateSpecificationError: (state, action) => {
            state.updateSpecificationInfo.status = ApiStatus.error;
            state.updateSpecificationInfo.data = action.payload;
        },
        deleteSpecification: (state, action) => {
            state.deleteSpecificationInfo.status = ApiStatus.loading;
            state.deleteSpecificationInfo.data = action.payload;
        },
        deleteSpecificationSuccess: (state, action) => {
            state.deleteSpecificationInfo.status = ApiStatus.success;
            state.deleteSpecificationInfo.data = action.payload;
        },
        deleteSpecificationError: (state, action) => {
            state.deleteSpecificationInfo.status = ApiStatus.error;
            state.deleteSpecificationInfo.data = action.payload;
        },
        updateSpecificationModal: (state, action) => {
            state.addSpecificationModal.isDialogOpen = true;
            state.addSpecificationModal.mode = action.payload.mode;
            state.addSpecificationModal.data = action.payload.data;
            state.addSpecificationModal.newData = action.payload.newData;
        },
        closeSpecificationModal: (state, action) => {
            state.addSpecificationModal.isDialogOpen = false;
            state.addSpecificationModal.mode = '';
            state.addSpecificationModal.data = null;
        },
        resetSpecificationStatus: (state, action) => {
            state.addSpecificationInfo.status = ApiStatus.loading;
            state.updateSpecificationInfo.status = ApiStatus.loading;
            state.deleteSpecificationInfo.status = ApiStatus.loading;
        },
    }
});

export const {
    getSpecificationList,
    getSpecificationListSuccess,
    getSpecificationListError,
    addSpecification,
    addSpecificationSuccess,
    addSpecificationError,
    updateSpecificationModal,
    closeSpecificationModal,
    updateSpecification, updateSpecificationSuccess, updateSpecificationError,
    deleteSpecification, deleteSpecificationSuccess, deleteSpecificationError,
    resetSpecificationStatus
} = specificationSlice.actions;

export default specificationSlice.reducer;
