import { takeEvery, put } from '@redux-saga/core/effects';
import AuthenticationService from 'service/authentication.service';
import { sendForgotPasswordMail, sendForgotPasswordMailSuccess, sendForgotPasswordMailError } from './userForgot.slice';

export const postForgotPasswordSaga = function* (action) {
    try {
        const res = yield AuthenticationService.forgotPassword(action.payload);
        yield put({
            type: sendForgotPasswordMailSuccess.type,
            payload: res.data
        });
    } catch (e) {
        yield put({
            type: sendForgotPasswordMailError.type,
            payload: e
        });
    }
}

export const postForgotPasswordWatch = function* () {
    yield takeEvery(sendForgotPasswordMail, postForgotPasswordSaga);
}