import { takeLatest, put } from '@redux-saga/core/effects';
import { getOrganizationSuccess, getOrganizationError, getOrganization } from './organization.slice';
import OrganizationService from 'service/organization.service';

export const fetchOrganizationSaga = function* () {
    try {
        const res = yield OrganizationService.getOrganisationDetails();
        yield put({
            type: getOrganizationSuccess.type,
            payload: res.data
        });
    } catch (e) {
        yield put({
            type: getOrganizationError.type,
            payload: e
        });
    }
}

export const fetchOrganizationWatch = function* () {
    yield takeLatest(getOrganization, fetchOrganizationSaga);
}