import React, { lazy, Suspense } from 'react';

export const CategoryComp = lazy(() => import('pages/home/components/Category'));
export const ProductsComp = lazy(() => import('pages/productList/ProductList'));
export const ProductsDescComp = lazy(() => import('pages/productDescription/ProductDescription'));
export const HomeComp = lazy(() => import('pages/home/Home'));
export const LoginComp = lazy(() => import('pages/Login/Login'));
export const SignupComp = lazy(() => import('pages/Signup/Signup'));
export const ForgotPasswordComp = lazy(() => import('pages/ForgotPassword/ForgotPassword'));
export const EmailVerifyComp = lazy(() => import('pages/EmailVerification/emailverify'));
export const VibromechHeaderComp = lazy(() => import('pages/home/components/Headers/VibromechHeader'));
export const SankarHeaderComp = lazy(() => import('pages/home/components/Headers/SankarHeader'));
export const AmrepHeaderComp = lazy(() => import('pages/home/components/Headers/Amrep'));
export const RegisteredUsersTableComp = lazy(() => import('pages/admin/users/userList'));
export const AdminUsersTableComp = lazy(() => import('pages/admin/users/adminUserList'));
export const AdminLoginComp = lazy(() => import ('pages/adminLogin/adminLogin'));
export const AdminForgotPasswordComp = lazy(() => import('pages/adminForgotPassword/adminForgotPassword'));
export const AdminResetPasswordComp = lazy(() => import('pages/adminResetPassword/adminResetPassword'));
export const GroupListComp = lazy(() => import('pages/admin/cataloguesettings/groups/groupsList'));
export const PartDetailsComp = lazy(() => import('pages/admin/partdetails/partdetails'));
export const CatalogueSettingsComp = lazy(() => import('pages/admin/cataloguesettings/cataloguesettings'));

export const DemoHeader = () => (
  <Suspense fallback={
    <div style={{background: 'lightgray', height: '180px'}}>
      <img src={`${window.pathUrl}/assets/images/${process.env.REACT_APP_PROJECT_NAME}/logo.png`} style={{float: 'left', width: '180px', minHeight: '80px'}} />
       <p style={{float: 'left', lineHeight: '80px', margin: '0px 0px 0px 20px'}}>Loading...</p>
    </div>}>
    <VibromechHeaderComp />
  </Suspense>
)

export const SankarHeader = () => (
  <Suspense fallback={
    <div style={{background: 'lightgray', height: '180px'}}>
      <img src={`${window.pathUrl}/assets/images/${process.env.REACT_APP_PROJECT_NAME}/logo.png`} style={{float: 'left', width: '180px', minHeight: '80px'}} />
       <p style={{float: 'left', lineHeight: '80px', margin: '0px 0px 0px 20px'}}>Loading...</p>
    </div>}>
      <SankarHeaderComp />
  </Suspense>
)

export const AmrepHeader = () => (
  <Suspense fallback={
    <div style={{background: 'lightgray', height: '180px'}}>
      <img src={`${window.pathUrl}/assets/images/${process.env.REACT_APP_PROJECT_NAME}/logo.png`} style={{float: 'left', width: '180px', minHeight: '80px'}} />
       <p style={{float: 'left', lineHeight: '80px', margin: '0px 0px 0px 20px'}}>Loading...</p>
    </div>}>
      <AmrepHeaderComp />
  </Suspense>
)

export const Home = () => (
    <Suspense fallback={
    <div style={{background: 'lightgray', height: '180px'}}>
      <img src={`${window.pathUrl}/assets/images/${process.env.REACT_APP_PROJECT_NAME}/logo.png`} style={{float: 'left', width: '180px', minHeight: '80px'}} />
       <p style={{float: 'left', lineHeight: '80px', margin: '0px 0px 0px 20px'}}>Loading...</p>
    </div>}>
        <HomeComp />
    </Suspense>
)

export const Login = () => (
  <Suspense fallback={
    <div style={{background: 'lightgray', height: '180px'}}>
      <img src={`${window.pathUrl}/assets/images/${process.env.REACT_APP_PROJECT_NAME}/logo.png`} style={{float: 'left', width: '180px', minHeight: '80px'}} />
       <p style={{float: 'left', lineHeight: '80px', margin: '0px 0px 0px 20px'}}>Loading...</p>
    </div>}>
      <LoginComp />
  </Suspense>
)

export const Signup = () => (
  <Suspense fallback={
    <div style={{background: 'lightgray', height: '180px'}}>
      <img src={`${window.pathUrl}/assets/images/${process.env.REACT_APP_PROJECT_NAME}/logo.png`} style={{float: 'left', width: '180px', minHeight: '80px'}} />
       <p style={{float: 'left', lineHeight: '80px', margin: '0px 0px 0px 20px'}}>Loading...</p>
    </div>}>
      <SignupComp />
  </Suspense>
)

export const ForgotPassword = () => (
  <Suspense fallback={
    <div style={{background: 'lightgray', height: '180px'}}>
      <img src={`${window.pathUrl}/assets/images/${process.env.REACT_APP_PROJECT_NAME}/logo.png`} style={{float: 'left', width: '180px', minHeight: '80px'}} />
       <p style={{float: 'left', lineHeight: '80px', margin: '0px 0px 0px 20px'}}>Loading...</p>
    </div>}>
      <ForgotPasswordComp />
  </Suspense>
)

export const ResetPassword = () => (
  <Suspense fallback={
    <div style={{background: 'lightgray', height: '180px'}}>
      <img src={`${window.pathUrl}/assets/images/${process.env.REACT_APP_PROJECT_NAME}/logo.png`} style={{float: 'left', width: '180px', minHeight: '80px'}} />
       <p style={{float: 'left', lineHeight: '80px', margin: '0px 0px 0px 20px'}}>Loading...</p>
    </div>}>
      <AdminResetPasswordComp />
  </Suspense>
)

export const EmailVerify = () => (
  <Suspense fallback={
    <div style={{background: 'lightgray', height: '180px'}}>
      <img src={`${window.pathUrl}/assets/images/${process.env.REACT_APP_PROJECT_NAME}/logo.png`} style={{float: 'left', width: '180px', minHeight: '80px'}} />
       <p style={{float: 'left', lineHeight: '80px', margin: '0px 0px 0px 20px'}}>Loading...</p>
    </div>}>
      <EmailVerifyComp />
  </Suspense>
)

export const Products = () => (
    <Suspense fallback={
    <div style={{background: 'lightgray', height: '180px'}}>
      <img src={`${window.pathUrl}/assets/images/${process.env.REACT_APP_PROJECT_NAME}/logo.png`} style={{float: 'left', width: '180px', minHeight: '80px'}} />
       <p style={{float: 'left', lineHeight: '80px', margin: '0px 0px 0px 20px'}}>Loading...</p>
    </div>}>
      <ProductsComp />
    </Suspense>
)

export const ProductDescription = () => (
    <Suspense fallback={
    <div style={{background: 'lightgray', height: '180px'}}>
      <img src={`${window.pathUrl}/assets/images/${process.env.REACT_APP_PROJECT_NAME}/logo.png`} style={{float: 'left', width: '180px', minHeight: '80px'}} />
      <p style={{float: 'left', lineHeight: '80px', margin: '0px 0px 0px 20px'}}>Loading...</p>
    </div>}>
      <ProductsDescComp />
    </Suspense>
)

export const Category = () => (
    <Suspense fallback={
    <div style={{background: 'lightgray', height: '180px'}}>
      <img src={`${window.pathUrl}/assets/images/${process.env.REACT_APP_PROJECT_NAME}/logo.png`} style={{float: 'left', width: '180px', minHeight: '80px'}} />
       <p style={{float: 'left', lineHeight: '80px', margin: '0px 0px 0px 20px'}}>Loading...</p>
    </div>}>
      <CategoryComp />
    </Suspense>
)
export const RegisteredUsersList = () => (
  <Suspense fallback={
    <div style={{background: 'lightgray', height: '180px'}}>
      <img src={`${window.pathUrl}/assets/images/${process.env.REACT_APP_PROJECT_NAME}/logo.png`} style={{float: 'left', width: '180px', minHeight: '80px'}} />
       <p style={{float: 'left', lineHeight: '80px', margin: '0px 0px 0px 20px'}}>Loading...</p>
    </div>}>
      <RegisteredUsersTableComp />
  </Suspense>
)
export const AdminUsersList = () => (
  <Suspense fallback={
    <div style={{background: 'lightgray', height: '180px'}}>
      <img src={`${window.pathUrl}/assets/images/${process.env.REACT_APP_PROJECT_NAME}/logo.png`} style={{float: 'left', width: '180px', minHeight: '80px'}} />
       <p style={{float: 'left', lineHeight: '80px', margin: '0px 0px 0px 20px'}}>Loading...</p>
    </div>}>
      <AdminUsersTableComp />
  </Suspense>
)

export const AdminLogin = () => (
  <Suspense fallback={
    <div style={{background: 'lightgray', height: '180px'}}>
      <img src={`${window.pathUrl}/assets/images/${process.env.REACT_APP_PROJECT_NAME}/logo.png`} style={{float: 'left', width: '180px', minHeight: '80px'}} />
       <p style={{float: 'left', lineHeight: '80px', margin: '0px 0px 0px 20px'}}>Loading...</p>
    </div>}>
      <AdminLoginComp />
  </Suspense>
)

export const AdminForgotPassword = () => (
  <Suspense fallback={<div>Waiting...</div>}>
      <AdminForgotPasswordComp />
  </Suspense>
)

export const GroupsList = () => (
  <Suspense fallback={
    <div style={{background: 'lightgray', height: '180px'}}>
      <img src={`${window.pathUrl}/assets/images/${process.env.REACT_APP_PROJECT_NAME}/logo.png`} style={{float: 'left', width: '180px', minHeight: '80px'}} />
       <p style={{float: 'left', lineHeight: '80px', margin: '0px 0px 0px 20px'}}>Loading...</p>
    </div>}>
      <GroupListComp />
  </Suspense>
) 

export const PartDetails = () => (
  <Suspense fallback={
    <div style={{background: 'lightgray', height: '180px'}}>
      <img src={`${window.pathUrl}/assets/images/${process.env.REACT_APP_PROJECT_NAME}/logo.png`} style={{float: 'left', width: '180px', minHeight: '80px'}} />
       <p style={{float: 'left', lineHeight: '80px', margin: '0px 0px 0px 20px'}}>Loading...</p>
    </div>}>
      <PartDetailsComp/>
  </Suspense>
)

export const CatalogueSettings = () => (
  <Suspense fallback={
    <div style={{background: 'lightgray', height: '180px'}}>
      <img src={`${window.pathUrl}/assets/images/${process.env.REACT_APP_PROJECT_NAME}/logo.png`} style={{float: 'left', width: '180px', minHeight: '80px'}} />
       <p style={{float: 'left', lineHeight: '80px', margin: '0px 0px 0px 20px'}}>Loading...</p>
    </div>}>
      <CatalogueSettingsComp/>
  </Suspense>
)