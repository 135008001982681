import { takeEvery, put } from '@redux-saga/core/effects';
import AuthenticationService from 'service/authentication.service';
import { 
    signupError,
    doSignup, 
    signupSuccess, 
    getCountries, 
    getCountriesSuccess, 
    getCountriesError, 
    sendVerificationMail,
    getIntrestedAs,
    getIntrestedAsSuccess,
    getIntrestedAsError
} from './userSignup.slice';


export const postUserDetailSaga = function* (action) {
    try {
        const res = yield AuthenticationService.signup(action.payload);
        yield put(sendVerificationMail(action.payload))
        yield put({
            type: signupSuccess.type,
            payload: res.data
        });
    } catch (e) {
        yield put({
            type: signupError.type,
            payload: e
        });
        return e;
    }
}

export const fetchCountriesSaga = function* () {
    try {
        const res = yield AuthenticationService.getCountries();
        yield put({
            type: getCountriesSuccess.type,
            payload: res.data
        });
    } catch (e) {
        yield put({
            type: getCountriesError.type,
            payload: e
        });
        return e;
    }
}

export const fetchIntrestedAsSaga = function* () {
    try {
        const res = yield AuthenticationService.getIntrestedAs();
        yield put({
            type: getIntrestedAsSuccess.type,
            payload: res.data
        });
    } catch (e) {
        yield put({
            type: getIntrestedAsError.type,
            payload: e
        });
        return e;
    }
}

export const postUserDetailWatch = function* () {
    yield takeEvery(doSignup, postUserDetailSaga);
}
export const fetchCountriesWatch = function* () {
    yield takeEvery(getCountries, fetchCountriesSaga);
}
export const fetchIntrestedAsWatch = function* () {
    yield takeEvery(getIntrestedAs, fetchIntrestedAsSaga);
}
