import { createSlice } from "@reduxjs/toolkit";
import { ApiStatus } from 'common/constant';

export const initialAdminLoginState = {
    loginInfo: {},
    forgotPwdInfo: {
        status: '',
        data: ''
    },
    countryInfo: {
        countries: [],
        status: ''
    },
    adminResetPassword: { status: '' },
};

export const adminLoginSlice = createSlice({
    name: "adminLogin",
    initialState: initialAdminLoginState,
    reducers: {
        loginUser: (state) => {
            state.loginInfo.status = ApiStatus.loading;
        },
        loginuserSuccess: (state, action) => {
            state.loginInfo.data = action.payload;
            state.loginInfo.status = ApiStatus.success;
        },
        loginuserError: (state, action) => {
            state.loginInfo.data = action.payload;
            state.loginInfo.status = ApiStatus.error;
        },
        resetLoginStatus: (state, action) => {
            state.loginInfo.status = ApiStatus.loading;
        },
        sendForgotPasswordEmail: (state) => {
            state.forgotPwdInfo.status = ApiStatus.loading
        },
        sendForgotPasswordEmailSuccess: (state, action) => {
            state.forgotPwdInfo.data = action.payload
            state.forgotPwdInfo.status = ApiStatus.success
        },
        sendForgotPasswordEmailError: (state, action) => {
            state.forgotPwdInfo.data = action.payload
            state.forgotPwdInfo.status = ApiStatus.error
        },
        getUserCountries: (state) => {
            state.countryInfo.status = ApiStatus.loading;
        },
        getUserCountriesSuccess: (state, action) => {
            state.countryInfo.countries = action.payload;
            state.countryInfo.status = ApiStatus.success;
        },
        getUserCountriesError: (state, action) => {
            state.countryInfo.countries = action.payload;
            state.countryInfo.status = ApiStatus.error;
        },
        adminResetPassword: (state) => {
            state.status = ApiStatus.loading;
        },
        adminResetPasswordSuccess: (state, action) => {
            state.adminResetPassword.data = action.payload;
            state.adminResetPassword.status = ApiStatus.success;
        },
        adminResetPasswordError: (state, action) => {
            state.adminResetPassword.data = action.payload;
            state.adminResetPassword.status = ApiStatus.error;
        },
    },
})

export const {
    loginUser,
    loginuserSuccess,
    loginuserError,
    sendForgotPasswordEmail,
    sendForgotPasswordEmailSuccess,
    sendForgotPasswordEmailError,
    getUserCountries,
    getUserCountriesSuccess,
    getUserCountriesError,
    adminResetPassword,
    adminResetPasswordSuccess,
    adminResetPasswordError,
    resetLoginStatus
} = adminLoginSlice.actions;

export default adminLoginSlice.reducer;