import { takeEvery, put } from '@redux-saga/core/effects';
import IndependentPartFiltersService from 'service/adminIndependentFilter.service';
import { getIndependentPartFilterList , getIndependentPartFilterListSuccess , getIndependentPartFilterListError } from './adminIndependentFilter.slice';

export const fetchIndependentPartFilterListSaga = function* () {
    try {
        const res = yield IndependentPartFiltersService.getIndependentPartFilterList();
        yield put({
            type: getIndependentPartFilterListSuccess.type,
            payload: res.data
        });
    } catch (e) {
        yield put({
            type: getIndependentPartFilterListError.type,
            payload: "IndependentPartFilterList failed to fetch"
        });
    }
}

export const fetchIndependentPartFilterListWatch = function* () {
    yield takeEvery(getIndependentPartFilterList, fetchIndependentPartFilterListSaga);
}