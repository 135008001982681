import { createTheme } from '@mui/material/styles';
import { pink, grey, green } from '@mui/material/colors'

const AmrepTheme = createTheme({
  palette: {
    primary: green,
    secondary: pink,
    text: {
      primary: '#005c37',
      secondary: grey[700],
      name: 'second',
    },
    signup: {
      buttonColor: '#005c37 !important',
      subcaptchaColor:'white',
      captchaInputRoot:'#ffffff',
      formLabelColor:'white !important',
      formBg:'#f5f5f5',
      inputRootBgColor:'#ffffff',
      selectBgColor:'#ffffff',
      cityBgColor:'#ffffff',
      btnSignupColor:'white !important',
      dialogBgColor:'#005c37 !important',
      closeIconColor:'white !important',
      fontControlColor:'#005c37 !important'
    },
    login:{
      captcha:'#ffffff',
      subCaptcha:'white',
      input:'#ffffff',
      link:'#005c37 !important',
      submitButtonColor:'#005c37 !important'
    },
    forgotpassword: {
      buttonColor:'#005c37',
      dividerLabelColor:'#005c37',
      link:'#005c37',
      submitButtonColor:'#005c37 !important'
    },
    adminforgotpassword:{
      formContainerbgColor:'#fafbfc',
      color:'#005c37',
      dividerColor:"1px solid #005c37"
    },
    resetpassword: {
      buttonColor:'#005c37',
      dividerLabelColor:'#005c37',
      link:'#005c37 !important',
      submitButtonColor:'#005c37 !important',
      border:'solid thin #005c37',
      color:'#005c37'
    },
    checkbox:{
     color:'#005c37 !important'
    },
    adminLogin:{
     formContainerBgColor:'#fafbfc'
    },
    addUser:{
       containerBgColor:'#F2FEF2',
       dropdownControlColor:'black',
       dialogTitleBgColor:'#005c37',
       dialogTitleColor:'white',
       muiButtonBgColor:'#005c37 !important'
    },
    userList:{
      containerBg:'#F2FEF2',
    },
    admin:{
      miniDrawerBg:'#005c37',
      menuIconColor: '#005c37 !important',
      activeColor: '#005c37'
    },

    partDetails: {
      columnHeader: '#005c37'
    },
    partImages: {
      columnHeader: '#005c37'
    },
    partFilter: {
      columnHeader: '#005c37'
    },
    oeNumber: {
      columnHeader: '#005c37'
    },
    subParts: {
      columnHeader: '#005c37'
    },
    catalogSetting: {
      bgColor: '#005c37',
      lightBgColor: '#d9ffd6',
      border:'2px solid #005c37'
    },
    productPageList:{
      columnHeader: '#005c37'
    },
    groupList:{
      columnHeader: '#005c37'
    },
    filterLabel:{
      columnHeader: '#005c37'
    },
    filterValues:{
      columnHeader: '#005c37'
    },
    specification:{
      columnHeader: '#005c37'
    },
    
    catalogItem:{
      itemCard:'#fff',
      itemCardHover:'#005c37',
      itemCardHoverBorder:'none',
      itemNameColor:'#fff',
      itemNameBackgroundColor:'#000',
      productCardFooterHoverColor:'none',
      itemCardBgColor:'#005c37',
      itemCardColor:'none',
      itemCardHoverColor:'none'
    },
    filterElement:{
        checked:'#005c37 !important',
        filterSelectBackgroundColor:'#005c37',
        filterSelectColor:'#fff'
    },
    Header:{
      navbarBackgroundColor:'rgba(0,0,0,.9)',
      menuItem:'#ffffff',
      menuItemHover:'#005c37',
      menuChildBackgroundColor:'#000000d1',
      searchInput:'#495057',
      searchInputBorder:'1px solid #005c37'
    },
    sideBar:{
      filterHeaderBackground:'#005c37',
      filterHeaderColor:'#fff',
      filterHeaderBorderTop:'#005c37',
      filterHeaderBefore:'rgba(255, 51, 0,0.09)  !important',
      filterContentHeaderText:'#005c37',
      filterContentHighlightBg:'#dae7e8 !important',
      filterContentHighlightColor:'black !important',
      filterContentWrapper:'white',
      filterContentScrollThumb:'#005c37',
    },
    productDescription:{
      breadCrumb:'#005c37',
      gridRowBorder:'1px solid #eaeaea',
      gridColumnFirstTypeBorder:'solid #eaeaea',
      gridColumnLastTypeBorder:'1px solid #eaeaea',
      gridColumnItemWrapperBorder:'1px solid #dedddd',
      gridColumnItemImgBorder:'1px solid #eaeaea',
      gridColumnItemContainerBorder:'1px solid #eaeaea',
      gridColumnItemContentBorder:'1px solid #005c37',
      gridColumnHeaderHeighlightBg:'#005c37 !important',
      gridColumnHeaderHeighlightColor:'white !important',
      gridColumnHeaderHeighlightHoverColor:'black !important',
      gridColumnHeaderHeighlightHoverBg:'white !important',
      gridColumnHeaderLinkBg:'white',
      gridColumnHeaderLinkColor:'#495057',
      gridColumnHeaderLinkBorder:'1px solid #dee2e6',
      gridColumnHeaderLinkBackground:'#f8f9fa',
      gridColumnTableDataColor:'#000',
      gridColumnTableDataBorder:'0.1px solid black',
      gridColumnTableHeadBorder:'0.1px solid black',
      gridColumnTableHeadBackground:'#005c37',
      gridColumnTableHeadColor:'white',
      gridColumnTableContentBorder:'0.1px solid black !important',
      gridColumnThumbPrevNext:'#005c37 !important',
      gridColumnTableDataFirstChild:'0.1px solid black !important',
      gridColumnTableDataLastChild:'0.1px solid black !important',
      specificationTableTableRow:'0.1px solid black',
      gridBtnBg:'#005c37',
      gridBtnColor:'white',
      btnSearchColor:'white',
      btnSearchBg:'#005c37',
      serachInputBorder:'1px solid #005c37',
      InnerImageZoomContainerBg:'#005c37',
      imageCardHoverBorderColor:'2px solid #005c37',
      onClickCloseBg:'#005c37 !important'
    },
    productList:{
      productCardHeader:'#005c37',
      productCardFooter:'#005c37',
      productCardFooterColor:'none',
      breadCrumb:'#005c37',
      mainContainerDataViewHeaderBg:'#fff',
      mainContainerDataViewHeaderColor:'#495057',
      mainContainerDataViewHeaderBorder:'1px solid #e9ecef',
      mainContainerDataViewContentColor:'#000000',
      mainContainerDataViewContentBg:'#f1f6f8',
      headerGridHighlightBorder:'#005c37',
      headerGridHighlightBackground:'#005c37',
      headerGridHighlightHoverBorder:'#005c37',
      headerGridHighlightHoverBackground:'#005c37',
      shareBtnBackground:'transparent',
      shareBtnColor:'#005c37',
      shareIconColor:'#005c37',
      productDescriptionBg:'#000000',
      productDescriptionColor:'#ffffff',
      productNameBg:'#000',
      productNameColor:'#fff',
      productListFooterHeight: '0px',
      productListfooterDisplay: 'none',
      productListTitleDisplay: 'flex',
      productListTitleHeight: '40px',
      productListItemBackground:'#dae7e8',
      productListDetailBorder:'1px solid #fff',
      productListDetailBackground:'#fff',
      viewMoreLinkColor:'white',
      viewMoreLinkBackground:'#005c37 !important',
      viewMoreLinkBorder:'1px solid #000 !important',
      gridColumnTableDataColor:'black',
      gridColumnTableDataBorder:'0.1px solid black',
      gridColumnTableDataFirstChild:'0.1px solid black',
    },
    BreadCrumb:{
      textParagraphColor:'#ffffff',
      linkStyle:'#ffffff',
      breadCrumbColor:'#ffffff'
    },
    Loader:{
      loaderContainerBgColor:'rgba(0,0,0,0.2)'
    },
    emailVerify:{
      btnStyle:'#005c37 !important'
    },
    Header:{
       navBarBgColor:'#005c37',
       menuContainerBgColor:'#005c37',
       menuContainerColor:'white',
       menuIconBgColor:'white',
       menuItemColor:'#ffffff',
       menuItemHoverColor:'none',
       menuChildBackground:'#000000d1',
       mobileMenuChildContainerBackground:'#ffffff !important',
       menuChildContainerBackground:'#f5f5f5',
       searchInputBorder:'1px solid #005c37',
       searchInputColor:'#495057',
       searchButtonBorder:'1px solid #005c37',
       searchButtonBgColor:'#fff'
    },
    toggleImg:{
      mobileFilterLogo:'#005c37'
    },
    toggleSearchIcon:{
      toggleSearchIconColor:'1px solid #005c37'
    },
    SocialShare:{
      messagePositionBgColor:'#005c37'
    },
    background: {
      default: '#eee',
      paper: '#fff',
    },
    divider: grey[300],
  },
  spacing: 8,
  typography: {
    fontFamily: [
      '"Hiragino Sans"',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      'Helvetica',
      'Arial',
      'sans-serif',
    ].join(','),
  },
  components: {
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {},
    },
    MuiTextField: {
      defaultProps: {
        variant: 'filled',
      },
    },
  },
});

export default AmrepTheme;
