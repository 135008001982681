import { takeLatest, put } from '@redux-saga/core/effects';
import { postOrganization, postOrganizationSuccess, postOrganizationError, getFilterLabel, getFilterLabelSuccess, getFilterLabelError  } from './cataloguesettings.slice';
import CatalogueSettingService from 'service/cataloguesettings.service';

export const fetchPostOrganizationSaga = function* (action) {
    try {
        const res = yield CatalogueSettingService.cataloguesettings(action.payload)
        yield put({
            type: postOrganizationSuccess.type,
            payload: res.data
        });
    } catch (e) {
        yield put({
            type: postOrganizationError.type,
            payload: e
        });
    }
}

export const fetchGetFilterLabelSaga = function* () {
    try {
        const res = yield CatalogueSettingService.filterlabel()
        yield put({
            type: getFilterLabelSuccess.type,
            payload: res.data
        });
    } catch (e) {
        yield put({
            type: getFilterLabelError.type,
            payload: e
        });
    }
}

export const fetchPostOrganizationWatch = function* () {
    yield takeLatest(postOrganization, fetchPostOrganizationSaga);
}

export const fetchGetFilterLabelWatch = function* () {
    yield takeLatest(getFilterLabel, fetchGetFilterLabelSaga);
}