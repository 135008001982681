import { createSlice } from "@reduxjs/toolkit";
import { ApiStatus } from 'common/constant';

export const initialFilterValuesListState = {
    addFilterValuesInfo: {
        status: ApiStatus.loading,
        data: ''
    },
    updateFilterValuesInfo: {
        status: ApiStatus.loading,
        data: ''
    },
    deleteFilterValuesInfo: {
        status: ApiStatus.loading,
        data: {}
    },
    addFilterValuesModal: {
        isDialogOpen: false,
        mode: '',
        data: null,
        filterDetails: null
    },
    list: { types: [] },
    status: ''
}

export const filterValuesSlice = createSlice({
    name: "filterValuesSlice",
    initialState: initialFilterValuesListState,
    reducers: {
        addFilterValues: (state) => {
            state.addFilterValuesInfo.status = ApiStatus.loading;
        },
        addFilterValuesSuccess: (state, action) => {
            state.addFilterValuesInfo.status = ApiStatus.success;
            state.addFilterValuesInfo.data = action.payload;
        },
        addFilterValuesError: (state, action) => {
            state.addFilterValuesInfo.status = ApiStatus.error;
            state.addFilterValuesInfo.data = action.payload;
        },
        updateFilterValues: (state) => {
            state.updateFilterValuesInfo.status = ApiStatus.loading;
        },
        updateFilterValuesSuccess: (state, action) => {
            state.updateFilterValuesInfo.status = ApiStatus.success;
            state.updateFilterValuesInfo.data = action.payload;
        },
        updateFilterValuesError: (state, action) => {
            state.updateFilterValuesInfo.status = ApiStatus.error;
            state.updateFilterValuesInfo.data = action.payload;
        },
        deleteFilterValues: (state, action) => {
            state.deleteFilterValuesInfo.status = ApiStatus.loading;
            state.deleteFilterValuesInfo.data = action.payload;
        },
        deleteFilterValuesSuccess: (state, action) => {
            state.deleteFilterValuesInfo.status = ApiStatus.success;
            state.deleteFilterValuesInfo.data = action.payload;
        },
        deleteFilterValuesError: (state, action) => {
            state.deleteFilterValuesInfo.status = ApiStatus.error;
            state.deleteFilterValuesInfo.data = action.payload;
        },
        resetFilterValuesStatus: (state, action) => {
            state.addFilterValuesInfo.status = ApiStatus.loading;
            state.updateFilterValuesInfo.status = ApiStatus.loading;
            state.deleteFilterValuesInfo.status = ApiStatus.loading;
        },
        updateFilterValuesModal: (state, action) => {
            state.addFilterValuesModal.isDialogOpen = true;
            state.addFilterValuesModal.mode = action.payload.mode;
            state.addFilterValuesModal.data = action.payload.data;
            state.addFilterValuesModal.filterDetails = action.payload.filterDetails;
        },
        closeFilterValuesModal: (state, action) => {
            state.addFilterValuesModal.isDialogOpen = false;
            state.addFilterValuesModal.mode = '';
            state.addFilterValuesModal.data = null;
        },
    }
})

export const {
    addFilterValues, addFilterValuesSuccess, addFilterValuesError,
    updateFilterValues, updateFilterValuesSuccess, updateFilterValuesError,
    deleteFilterValues, deleteFilterValuesSuccess, deleteFilterValuesError,
    resetFilterValuesStatus, updateFilterValuesModal, closeFilterValuesModal

} = filterValuesSlice.actions;

export default filterValuesSlice.reducer;