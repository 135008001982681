import { takeEvery, put } from '@redux-saga/core/effects';
import AuthenticationService from 'service/authentication.service';
import { verifyEmail, verifyEmailSuccess, verifyEmailError } from '../userSignup/userSignup.slice';

export const postEmailVerifySaga = function* (action) {
    try {
        const res = yield AuthenticationService.emailverify(action.payload);
        yield put({
            type: verifyEmailSuccess.type,
            payload: res.data
        });
    } catch (e) {
        yield put({
            type: verifyEmailError.type,
            payload: e
        });
    }
}

export const postEmailVerifyWatch = function* () {
    yield takeEvery(verifyEmail, postEmailVerifySaga);
}