import { createSlice } from "@reduxjs/toolkit";
import { ApiStatus } from 'common/constant';

export const initialProductPageListState = {
    addProductInfo: {
        status: ApiStatus.loading,
        data: ''
    },
    updateProductInfo: {
        status: ApiStatus.loading,
        data: ''
    },
    addProductModal: {
        mode: '',
        data: null
    },
    list: { types : []},
    status: ''

}

export const productPageListSlice = createSlice({
    name: "productPageListSlice",
    initialState: initialProductPageListState,
    reducers: {
        addProduct: (state) => {
            state.updateProductInfo.status = ApiStatus.loading;
        },
        getProductPageList: (state) => {
            state.status = ApiStatus.loading;
        },
        getProductPageListSuccess: (state, action) => {
            state.list = action.payload[0];
            state.status = ApiStatus.success;
        },
        getProductPageListError: (state, action) => {
            state.list = action.payload;
            state.status = ApiStatus.error;
        },
        updateProduct: (state) => {
            state.updateProductInfo.status = ApiStatus.loading;
        },
        updateProductSuccess: (state, action) => {
            state.updateProductInfo.status = ApiStatus.success;
            state.updateProductInfo.data = action.payload;
        },
        updateProductError: (state, action) => {
            state.updateProductInfo.status = ApiStatus.error;
            state.updateProductInfo.data = action.payload;
        },
        updateProductModal: (state, action) => {
            state.addProductModal.isDialogOpen = true;
            state.addProductModal.mode = action.payload.mode;
            state.addProductModal.data = action.payload.data;
            state.addProductModal.newData = action.payload.newData;
        },
        closeProductModal: (state, action) => {
            state.addProductModal.isDialogOpen = false;
            state.addProductModal.mode = '';
            state.addProductModal.data = null;
        },
        resetProductConfigUpdate: (state) => {
            state.updateProductInfo.status = ApiStatus.loading;
        }
    }
})

export const {addProduct, getProductPageList, getProductPageListSuccess, getProductPageListError, updateProduct, updateProductSuccess,
    updateProductError, updateProductModal, closeProductModal, resetProductConfigUpdate } = productPageListSlice.actions;
export default productPageListSlice.reducer;