import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import App from './App';
import ThemeProvider from './theme/ThemeProvider';
import './i18n';
import configureAppStore from 'store/store';

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <ThemeProvider>
    <Provider store={configureAppStore()}>
      <App />
    </Provider>
  </ThemeProvider>
)
