import { put, takeEvery } from '@redux-saga/core/effects';
import AuthenticationService from 'service/authentication.service';
import AdminService from 'service/admin.service';
import {
    loginUser,
    loginuserSuccess,
    loginuserError,
    sendForgotPasswordEmail,
    sendForgotPasswordEmailSuccess,
    sendForgotPasswordEmailError,
    getUserCountries,
    getUserCountriesSuccess,
    getUserCountriesError,
    adminResetPassword,
    adminResetPasswordSuccess,
    adminResetPasswordError
} from "./adminLogin.slice";

export const fetchAdminDetailSaga = function* (action) {
    try {
        const res = yield AuthenticationService.login(action.payload);
        yield put({
            type: loginuserSuccess.type,
            payload: res
        });

    } catch (e) {
        yield put({
            type: loginuserError.type,
            payload: e
        })
    }
}

export const adminForgotpasswordSaga = function* (action) {
    try {
        const res = AdminService.adminforgotpassword(action.payload)
        yield put({
            type: sendForgotPasswordEmailSuccess.type,
            payload: res
        })

    } catch (e) {
        yield put({
            type: sendForgotPasswordEmailError.type,
            payload: e
        })
    }
}

export const adminResetPasswordSaga = function* (action) {
    try {
        const res = yield AdminService.adminresetpassword(action.payload);
        yield put({
            type: adminResetPasswordSuccess.type,
            payload: res.data
        });
    } catch (e) {
        yield put({
            type: adminResetPasswordError.type,
            payload: e
        });
    }
}

export const fetchUserCountriesSaga = function* () {
    try {
        const res = yield AuthenticationService.getCountries();
        yield put({
            type: getUserCountriesSuccess.type,
            payload: res.data
        });
    } catch (e) {
        yield put({
            type: getUserCountriesError.type,
            payload: e
        });
        return e;
    }
}

export const adminForgotpasswordWatch = function* () {
    yield takeEvery(sendForgotPasswordEmail, adminForgotpasswordSaga)
}

export const fetchAdminDetailWatch = function* () {
    yield takeEvery(loginUser, fetchAdminDetailSaga)
}
export const fetchUserCountriesWatch = function* () {
    yield takeEvery(getUserCountries, fetchUserCountriesSaga);
}

export const adminResetPasswordWatch = function* () {
    yield takeEvery(adminResetPassword, adminResetPasswordSaga);
}