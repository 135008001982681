import axiosInstance from 'service/api';
import Constants from 'common/constant';

export default class ProductService {
    static getProductInfo(query) {
        const endPoint = `${window.config.genericGet}?collectionName=${window.config.collections['parts']}&search=${query}`;
        return axiosInstance.get(endPoint);
    }
    static getProductMake(query) {
        const endPoint = `${window.config.genericGet}?collectionName=${window.config.collections['masterLookup']}&search=${query}`;
        return axiosInstance.get(endPoint);
    }
    static getProductSpecification(query) {
        const endPoint = `${window.config.genericGet}?collectionName=${window.config.collections['masterLookup']}&search=${query}`;
        return axiosInstance.get(endPoint);
    }
    static getProductSpecificationList() {
        const productSpecificationQuery = `{'lookup_id':'${Constants.masterLookup.specifications.name}'}`;
        const endPoint = `${window.config.genericGet}?collectionName=${window.config.collections['masterLookup']}&search=${productSpecificationQuery}`;
        return axiosInstance.get(endPoint);
    }

    static addSpecification(values) {
        const body = {
            collectionName: 'master_lookups',
            lookup_id: "specifications",
            data: [values]
        }
        const endPoint = `${window.config.InsertPartSpecification}`;
        return axiosInstance.post(endPoint, body);
    }

    static updateSpecification(values) {
        const props = { ...values };
        delete props._id
        const body = {
            collectionName: 'master_lookups',
            lookup_id: "specifications",
            data: [props],
            condition: [{ _id: values._id }]
        }
        const endPoint = `${window.config.UpdatePartSpecification}`;
        return axiosInstance.post(endPoint, body);
    }

    static deleteSpecification(values) {
        const body = {
            collectionName: 'master_lookups',
            lookup_id: "specifications",
            data: [values],
            delete: [{ _id: values._id }]

        }
        const endPoint = `${window.config.DeletePartSpecification}`;
        return axiosInstance.post(endPoint, body);
    }
}