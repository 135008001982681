import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  Routes,
  Route,
  HashRouter,
  BrowserRouter as Router
} from 'react-router-dom';
import {
  Home,
  Login,
  Signup,
  ForgotPassword,
  Products,
  Category,
  RegisteredUsersList,
  AdminUsersList,
  ProductDescription,
  EmailVerify,
  AdminLogin,
  AdminForgotPassword,
  ResetPassword,
  CatalogueSettings,
  PartDetails
} from 'lazyLoaded';
import Admin from 'pages/admin/admin';
import Analytics from 'pages/admin/analytics/analytics';
import { getOrganization } from 'redux/organization/organization.slice';
import './App.css';
import PrivateRoutes from 'pages/admin/PrivateRoute';

const App = () => {
  const dispatch = useDispatch();
  const link = document.querySelector("link[rel~='icon']");
  link.href = `${window.pathUrl}/assets/images/${process.env.REACT_APP_PROJECT_NAME}/favicon.jpg`;

  useEffect(() => {
    dispatch(getOrganization());
  }, []);

  // useEffect(() => {
  //   if (process.env.REACT_APP_PROJECT_NAME === "amrep" || window.config.tenant === "amrep") {
  //     const dynamicFunction = `(function(c,l,a,r,i,t,y){ c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)}; t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i; y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y); })(window, document, "clarity", "script", "i58cufvfrf");`
  //     const rootBody = document.querySelector("head");
  //     const data = document.getElementById('analytics');
  //     data.innerHTML = dynamicFunction;
  //     rootBody.appendChild(data);

  //   } else if (process.env.REACT_APP_PROJECT_NAME === "demo" || window.config.tenant === "demo") {
  //     const dynamicFunction = `(function(c,l,a,r,i,t,y){ c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)}; t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i; y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y); })(window, document, "clarity", "script", "i9ukc2zp5s");`
  //     const rootBody = document.querySelector("head");
  //     const data = document.getElementById('analytics');
  //     data.innerHTML = dynamicFunction;
  //     rootBody.appendChild(data);
  //   }
  // }, [])

  useEffect(() => {
    const tenant = process.env.REACT_APP_PROJECT_NAME || window.config.tenant;
  
    const loadClarityScript = (scriptId) => {
      const dynamicFunction = `(function(c,l,a,r,i,t,y){ c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)}; t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i; y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y); })(window, document, "clarity", "script", "${scriptId}");`;
      
      const rootBody = document.querySelector("head");
      const data = document.getElementById('analytics');
      
      if (data) {
        data.textContent = dynamicFunction;
        rootBody.appendChild(data);
      }
    };
  
    if (tenant === "amrep") {
      loadClarityScript("i58cufvfrf");
    } else if (tenant === "demo") {
      loadClarityScript("i9ukc2zp5s");
    }
  }, []);
  

  return (
    <HashRouter>
      <Routes>
        <Route path='/login' element={<Login />}></Route>
        <Route path='/signup' element={<Signup />}></Route>
        <Route path='/forgotpassword' element={<ForgotPassword />}></Route>
        <Route path={`/verify-account/:code`} element={<EmailVerify />}></Route>
        <Route path='/adminforgotpassword' element={<AdminForgotPassword />}></Route>
        <Route path='/reset-password/:code' element={<ResetPassword />}></Route>
        <Route path='/admin/login' element={<AdminLogin />}></Route>
        <Route element={<PrivateRoutes />}>
          <Route path='/admin' element={<Admin />}>
            <Route path='/admin/registereduser' element={<RegisteredUsersList />} ></Route>
            <Route path='/admin/analytics' element={<Analytics />}></Route>
            <Route path='/admin/users' element={<AdminUsersList />}></Route>
            <Route path='/admin/cataloguesettings' element={<CatalogueSettings />}></Route>
            <Route index element={<PartDetails />} exact></Route>
            <Route path='/admin/partdetails' element={<PartDetails />} exact></Route>
          </Route>
        </Route>
        <Route path='/' exact element={<Home />}>
          <Route index element={<Category />} exact></Route>
          <Route path='/products' element={<Products />}></Route>
          <Route path='/product-description' element={<ProductDescription />}></Route>
        </Route>
      </Routes>
    </HashRouter>
  )
}

export default App;
