import { createSlice } from "@reduxjs/toolkit";
import { ApiStatus } from 'common/constant';

export const initialUserSignupState = {
    user:[],
    signupStatus:'',
    countries:[],
    IntrestedAs:[],
    status: ''
  };

export const userSignupSlice = createSlice({
    name: "userSignup",
    initialState: initialUserSignupState,
    reducers:{
        doSignup:(state) => {
            state.signupStatus = ApiStatus.loading;
        },
        signupSuccess:(state, action) => {
            state.user = action.payload;
            state.signupStatus = ApiStatus.success;
        },
        signupError:(state, action) => {
            state.user = action.payload;
            state.signupStatus = ApiStatus.error;
        },
        sendVerificationMail:(state) => {
            state.status = ApiStatus.loading;
        },
        sendVerificationMailSuccess:(state, action) => {
            state.user = action.payload;
            state.status = ApiStatus.success;
        },
        sendVerificationMailError:(state, action) => {
            state.user = action.payload;
            state.status = ApiStatus.error;
        },
        verifyEmail:(state) => {
            state.status = ApiStatus.loading;
        },
        verifyEmailSuccess:(state, action) => {
            state.user = action.payload;
            state.status = ApiStatus.success;
        },
        verifyEmailError:(state, action) => {
            state.user = action.payload;
            state.status = ApiStatus.error;
        },
        getCountries:(state) => {
            state.status = ApiStatus.loading;
        },
        getCountriesSuccess:(state, action) => {
            state.countries = action.payload;
            state.status = ApiStatus.success;
        },
        getCountriesError:(state, action) => {
            state.countries= action.payload;
            state.status = ApiStatus.error;
        },
        getIntrestedAs:(state) => {
            state.status = ApiStatus.loading;
        },
        getIntrestedAsSuccess:(state, action) => {
            state.IntrestedAs = action.payload;
            state.status = ApiStatus.success;
        },
        getIntrestedAsError:(state, action) => {
            state.IntrestedAs= action.payload;
            state.status = ApiStatus.error;
        },
    },
})

export const { 
    doSignup, 
    signupSuccess, 
    signupError,
    sendVerificationMail,
    sendVerificationMailSuccess,
    sendVerificationMailError,
    verifyEmail,
    verifyEmailSuccess,
    verifyEmailError,
    getCountries,
    getCountriesSuccess,
    getCountriesError,
    getIntrestedAs,
    getIntrestedAsSuccess,
    getIntrestedAsError

} = userSignupSlice.actions;

export default userSignupSlice.reducer;