import { createSlice } from "@reduxjs/toolkit";
import { ApiStatus } from 'common/constant';

export const initialfilterStackState = {
    filterStack: '',
    status: '',
    draggableList:[]
};

export const filterStackSlice = createSlice({
    name: "filterStack",
    initialState: initialfilterStackState,
    reducers: {
        updatefilterStack: (state) => {
            state.status = ApiStatus.loading;
        },
        updatefilterStackSuccess: (state, action) => {
            state.filterStack = action.payload;
            state.status = ApiStatus.success;
        },
        updatefilterStackError: (state, action) => {
            state.filterStack = action.payload;
            state.status = ApiStatus.error;
        },
        updateDraggablefilterStack: (state) => {
            state.status = ApiStatus.loading;
        },
        updateDraggablefilterStackSuccess: (state, action) => {
            state.draggableList = action.payload;
            state.status = ApiStatus.success;
        },
        updateDraggablefilterStackError: (state, action) => {
            state.draggableList = action.payload;
            state.status = ApiStatus.error;
        }
    },
})

export const { updatefilterStack, updatefilterStackSuccess, updatefilterStackError, updateDraggablefilterStack, updateDraggablefilterStackError, updateDraggablefilterStackSuccess } = filterStackSlice.actions;

export default filterStackSlice.reducer;