import { takeEvery, put } from '@redux-saga/core/effects';
import AuthenticationService from 'service/authentication.service';
import { sendVerificationMail, sendVerificationMailSuccess, sendVerificationMailError } from '../userSignup/userSignup.slice';

export const postEmailVerificationSaga = function* (action) {
    try {
        const res = yield AuthenticationService.emailverification(action.payload);
            yield put({
                type: sendVerificationMailSuccess.type,
                payload: res.data
            });
    } catch (e) {
        yield put({
            type: sendVerificationMailError.type,
            payload: e
        });
    }
}

export const postEmailVerificationWatch = function* () {
    yield takeEvery(sendVerificationMail, postEmailVerificationSaga);
}