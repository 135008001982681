import { createSlice } from "@reduxjs/toolkit";
import { ApiStatus } from 'common/constant';

export const initialIndependentFilterState = {
    independentFilterInfo : {
        status: ApiStatus.loading,
        mode:'',
        isDialogOpen: false
    },
    addIndependentFilterInfo: {
        status: ApiStatus.loading,
        data: ''
    },
    updateIndependentFilterInfo: {
        status: ApiStatus.loading,
        data: ''
    },
    deleteIndependentFilterInfo: {
        status: ApiStatus.loading,
        data: ''
    },
    addIndependentFilterModal: {
        isDialogOpen: false,
        mode: '',
        data: null,
        filterDetails: null
    },
    addIndependentFilterInfoModal: {
        isDialogOpen: false,
        mode: '',
        data: null,
    },
    list: { types: [] },
    status: ''
}

export const independentFilterSlice = createSlice({
    name: "IndependentFilterSlice",
    initialState: initialIndependentFilterState,
    reducers: {
        addIndependentFilter: (state) => {
            state.addIndependentFilterInfo.status = ApiStatus.loading;
        },
        addIndependentFilterSuccess: (state, action) => {
            state.addIndependentFilterInfo.status = ApiStatus.success;
            state.addIndependentFilterInfo.data = action.payload;
        },
        addIndependentFilterError: (state, action) => {
            state.addIndependentFilterInfo.status = ApiStatus.error;
            state.addIndependentFilterInfo.data = action.payload;
        },
        updateIndependentFilter: (state, action) => {
            state.updateIndependentFilterInfo.status = ApiStatus.loading;
        },
        updateIndependentFilterSuccess: (state, action) => {
            state.updateIndependentFilterInfo.status = ApiStatus.success;
            state.updateIndependentFilterInfo.data = action.payload;
        },
        updateIndependentFilterError: (state, action) => {
            state.updateIndependentFilterInfo.status = ApiStatus.error;
            state.updateIndependentFilterInfo.data = action.payload;
        },
        deleteIndependentFilter: (state, action) => {
            state.deleteIndependentFilterInfo.status = ApiStatus.loading;
        },
        deleteIndependentFilterSuccess: (state, action) => {
            state.deleteIndependentFilterInfo.status = ApiStatus.success;
            state.deleteIndependentFilterInfo.data = action.payload;
        },
        deleteIndependentFilterError: (state, action) => {
            state.deleteIndependentFilterInfo.status = ApiStatus.error;
            state.deleteIndependentFilterInfo.data = action.payload;
        },
        resetIndependentFilterStatus: (state, action) => {
            state.addIndependentFilterInfo.status = ApiStatus.loading;
            state.updateIndependentFilterInfo.status = ApiStatus.loading;
            state.deleteIndependentFilterInfo.status = ApiStatus.loading;
        },
        updateIndependentFilterModal: (state, action) => {
            state.addIndependentFilterModal.isDialogOpen = true;
            state.addIndependentFilterModal.mode = action.payload.mode;
            state.addIndependentFilterModal.data = action.payload.data;
            state.addIndependentFilterModal.filterDetails = action.payload.filterDetails;
        },
        updateIndependentFilterInfoModal: (state, action) => {
            state.addIndependentFilterInfoModal.isDialogOpen = true;
            state.addIndependentFilterInfoModal.mode = action.payload.mode;
            state.addIndependentFilterInfoModal.data = action.payload.data;
        },
        closeIndependentFilterModal: (state, action) => {
            state.addIndependentFilterModal.isDialogOpen = false;
            state.addIndependentFilterModal.mode = '';
            state.addIndependentFilterModal.data = null;
        }
    }
})

export const { 
    addIndependentFilter, addIndependentFilterSuccess, addIndependentFilterError,
    updateIndependentFilter, updateIndependentFilterError, updateIndependentFilterSuccess,
    deleteIndependentFilter, deleteIndependentFilterSuccess, deleteIndependentFilterError,
    resetIndependentFilterStatus,updateIndependentFilterModal,closeIndependentFilterModal,updateIndependentFilterInfoModal
} = independentFilterSlice.actions;

export default independentFilterSlice.reducer;