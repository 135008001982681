import { createSlice } from "@reduxjs/toolkit";
import { ApiStatus } from 'common/constant';

export const initialGroupListState = {
    addGroupInfo:{
        status: ApiStatus.loading,
        data: ''
    },
    updateGroupInfo:{
        status: ApiStatus.loading,
        data: ''
    },
    deleteGroupInfo:{
        status: ApiStatus.loading,
        data: {}
    },
    addFilterLabelInfo:{
        status: ApiStatus.loading,
        data: ''
    },
    updateFilterLabelInfo:{
        status: ApiStatus.loading,
        data: ''
    },
    deleteFilterLabelInfo:{
        status: ApiStatus.loading,
        data: {}
    },
    addGroupModal: {
        isDialogOpen: false,
        mode: '',
        data: null,
        newData:null
    },
    list: {types: []},
    status: ''
}

export const categoriesListSlice = createSlice({
    name: "categoriesListSlice",
    initialState: initialGroupListState,
    reducers:{
        getGroupList: (state) => {
            state.status = ApiStatus.loading;
        },
        getGroupListSuccess: (state, action) => {
            state.list = action.payload[0];
            state.status = ApiStatus.success;
        },
        getGroupListError: (state, action) => {
            state.list = action.payload;
            state.status = ApiStatus.error;
        },
        addGroup:(state) => {
            state.addGroupInfo.status = ApiStatus.loading;
        },
        addGroupSuccess:(state, action) => {
            state.addGroupInfo.status = ApiStatus.success;
            state.addGroupInfo.data = action.payload;
        },
        addGroupError:(state, action) => {
            state.addGroupInfo.status = ApiStatus.error;
            state.addGroupInfo.data = action.payload;
        },
        updateGroup:(state) => {
            state.updateGroupInfo.status = ApiStatus.loading;
        },
        updateGroupSuccess:(state, action) => {
            state.updateGroupInfo.status = ApiStatus.success;
            state.updateGroupInfo.data = action.payload;
        },
        updateGroupError:(state, action) => {
            state.updateGroupInfo.status = ApiStatus.error;
            state.updateGroupInfo.data = action.payload;
        },
        deleteGroup:(state,action) => {
            state.deleteGroupInfo.status = ApiStatus.loading;
            state.deleteGroupInfo.data = action.payload;
        },
        deleteGroupSuccess:(state, action) => {
            state.deleteGroupInfo.status = ApiStatus.success;
            state.deleteGroupInfo.data = action.payload;
        },
        deleteGroupError:(state, action) => {
            state.deleteGroupInfo.status = ApiStatus.error;
            state.deleteGroupInfo.data = action.payload;
        },
        addFilterLabel:(state) => {
            state.addFilterLabelInfo.status = ApiStatus.loading;
        },
        addFilterLabelSuccess:(state, action) => {
            state.addFilterLabelInfo.status = ApiStatus.success;
            state.addFilterLabelInfo.data = action.payload;
        },
        addFilterLabelError:(state, action) => {
            state.addFilterLabelInfo.status = ApiStatus.error;
            state.addFilterLabelInfo.data = action.payload;
        },
        updateFilterLabel:(state) => {
            state.updateFilterLabelInfo.status = ApiStatus.loading;
        },
        updateFilterLabelSuccess:(state, action) => {
            state.updateFilterLabelInfo.status = ApiStatus.success;
            state.updateFilterLabelInfo.data = action.payload;
        },
        updateFilterLabelError:(state, action) => {
            state.updateFilterLabelInfo.status = ApiStatus.error;
            state.updateFilterLabelInfo.data = action.payload;
        },
        deleteFilterLabel:(state,action) => {
            state.deleteFilterLabelInfo.status = ApiStatus.loading;
            state.deleteFilterLabelInfo.data = action.payload;
        },
        deleteFilterLabelSuccess:(state, action) => {
            state.deleteFilterLabelInfo.status = ApiStatus.success;
            state.deleteFilterLabelInfo.data = action.payload;
        },
        deleteFilterLabelError:(state, action) => {
            state.deleteFilterLabelInfo.status = ApiStatus.error;
            state.deleteFilterLabelInfo.data = action.payload;
        },
        resetFilterLabelStatus:(state, action) => {
            state.addFilterLabelInfo.status = ApiStatus.loading;
            state.updateFilterLabelInfo.status = ApiStatus.loading;
            state.deleteFilterLabelInfo.status = ApiStatus.loading;
        },
        resetGroupStatus:(state, action) => {
            state.addGroupInfo.status = ApiStatus.loading;
            state.updateGroupInfo.status = ApiStatus.loading;
            state.deleteGroupInfo.status = ApiStatus.loading;
        },
        updateGroupModal:(state, action) => {
            state.addGroupModal.isDialogOpen = true;
            state.addGroupModal.mode = action.payload.mode;
            state.addGroupModal.data = action.payload.data;
            state.addGroupModal.newData = action.payload.newData;
        },
        closeGroupModal:(state, action) => {
            state.addGroupModal.isDialogOpen = false;
            state.addGroupModal.mode = '';
            state.addGroupModal.data = null;
        },
    }
})

export const { getGroupList , getGroupListSuccess ,getGroupListError,
    addGroup,addGroupSuccess,addGroupError,
    updateGroup,updateGroupSuccess,updateGroupError,
    deleteGroup,deleteGroupSuccess,deleteGroupError,
    resetGroupStatus,updateGroupModal,closeGroupModal,
    addFilterLabel, addFilterLabelSuccess, addFilterLabelError,
    updateFilterLabel, updateFilterLabelSuccess, updateFilterLabelError,
    deleteFilterLabel, deleteFilterLabelSuccess, deleteFilterLabelError,
    resetFilterLabelStatus

} = categoriesListSlice.actions;

export default categoriesListSlice.reducer;