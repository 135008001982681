import { createSlice } from "@reduxjs/toolkit";
import { ApiStatus } from "common/constant";

export const initialBulkUploadState = {
    bulkUploadData: [],
    status: '',
    templateData: {
        columns: [],
        status: ''
    },
    updatedJsonData: {
        status: '',
    },
    finalUploadedData: [],
    bulkUploadResult: {
        status: ''
    }
};

export const bulkUploadSlice = createSlice({
    name: "bulkUploadData",
    initialState: initialBulkUploadState,
    reducers: {
        setBulkUploadData: (state, action) => {
            state.bulkUploadData = action.payload;
            state.finalUploadedData = action.payload;
            state.status = ApiStatus.success;
        },
        updateBulkRowData: (state, action) => {
            const rowIndex = state.bulkUploadData.findIndex((obj) => obj.SerialNo === action.payload.SerialNo);
            const data = [...state.bulkUploadData];
            data[rowIndex] = action.payload;
            state.bulkUploadData = data;
            state.updatedJsonData.status = 'error';
        },
        resetBulkUploadStatus:(state, action) =>  {
            state.status = '';
        },
        getTemplate: (state, action) => {
            state.templateData.status = ApiStatus.loading;
        },
        getTemplateSuccess:(state, action) =>  {
            state.templateData.status = ApiStatus.success;
            const payload = [...action.payload];
            const columns = [{label: 'SerialNo'}];
            if (payload.length) {
                payload.forEach((str) => {
                    columns.push({ label: str })
                })
            }
            state.templateData.columns = columns;
        },
        getTemplateFailure:(state, action) =>  {
            state.templateData.status = 'error';
        },
        updateTemplateData: (state, action) => {
            state.updatedJsonData.status = ApiStatus.loading;
        },
        updateTemplateDataSuccess:(state, action) =>  {
            state.updatedJsonData.status = ApiStatus.success;
        },
        updateTemplateDataFailure:(state, action) =>  {
            state.updatedJsonData.status = 'error';
        },
        bulkUploadFiles:(state, action) =>  {
            state.bulkUploadResult.status = '';
        },
        bulkUploadSuccess:(state, action) =>  {
            state.bulkUploadResult.status = ApiStatus.success;
        },
        bulkUploadFailure:(state, action) =>  {
            state.bulkUploadResult.status = ApiStatus.error;
        },
        
    },
})

export const { setBulkUploadData, resetBulkUploadStatus, getTemplate, getTemplateSuccess, getTemplateFailure,
    updateTemplateData, updateTemplateDataSuccess, updateTemplateDataFailure, updateBulkRowData,
    bulkUploadFiles, bulkUploadSuccess, bulkUploadFailure } = bulkUploadSlice.actions;

export default bulkUploadSlice.reducer;