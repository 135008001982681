import { createSlice } from "@reduxjs/toolkit";
import { ApiStatus } from 'common/constant';

export const initialUserListState = {
    adduserInfo: {
        status: ApiStatus.loading,
        data: ''
    },
    updateuserInfo: {
        status: ApiStatus.loading,
        data: ''
    },
    deleteuserInfo: {
        status: ApiStatus.loading,
        data: ''
    },
    addUserModal: {
        isDialogOpen: false,
        mode: '',
        data: null
    },
    list: [],
    status: ''
};

export const userListSlice = createSlice({
    name: "userListSlice",
    initialState: initialUserListState,
    reducers: {
        getUserList: (state) => {
            state.status = ApiStatus.loading;
        },
        getUserListSuccess: (state, action) => {
            state.list = action.payload;
            state.status = ApiStatus.success;
        },
        getUserListError: (state, action) => {
            state.list = action.payload;
            state.status = ApiStatus.error;
        },
        addUser: (state) => {
            state.adduserInfo.status = ApiStatus.loading;
        },
        addUserSuccess: (state, action) => {
            state.adduserInfo.status = ApiStatus.success;
            state.adduserInfo.data = action.payload;
        },
        addUserError: (state, action) => {
            state.adduserInfo.status = ApiStatus.error;
            state.adduserInfo.data = action.payload;
        },
        updateUser: (state) => {
            state.updateuserInfo.status = ApiStatus.loading;
        },
        updateUserSuccess: (state, action) => {
            state.updateuserInfo.status = ApiStatus.success;
            state.updateuserInfo.data = action.payload;
        },
        updateUserError: (state, action) => {
            state.updateuserInfo.status = ApiStatus.error;
            state.updateuserInfo.data = action.payload;
        },
        deleteUser: (state) => {
            state.deleteuserInfo.status = ApiStatus.loading;
        },
        deleteUserSuccess: (state, action) => {
            state.deleteuserInfo.status = ApiStatus.success;
            state.deleteuserInfo.data = action.payload;
        },
        deleteUserError: (state, action) => {
            state.deleteuserInfo.status = ApiStatus.error;
            state.deleteuserInfo.data = action.payload;
        },
        resetAddUserStatus: (state, action) => {
            state.adduserInfo.status = ApiStatus.loading;
        },
        updateModal: (state, action) => {
            state.addUserModal.isDialogOpen = true;
            state.addUserModal.mode = action.payload.mode;
            state.addUserModal.data = action.payload.data;
        },
        closeModal: (state, action) => {
            state.addUserModal.isDialogOpen = false;
            state.addUserModal.mode = '';
            state.addUserModal.data = null;
        },
        updateFilterData: (state, action) => {
            state.filteredDataState = true;
        },
    },
})

export const { addUser, addUserSuccess, addUserError, getUserList, getUserListSuccess, getUserListError,
    resetAddUserStatus, updateModal, closeModal, updateUser, updateUserSuccess, updateUserError, deleteUser,
    deleteUserSuccess, deleteUserError } = userListSlice.actions;

export default userListSlice.reducer;