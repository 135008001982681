import { takeEvery, put } from '@redux-saga/core/effects';
import AdminService from 'service/userlist.service';
import { addUser, addUserSuccess, addUserError, getUserList, getUserListSuccess, getUserListError, closeModal,
updateUser, updateUserSuccess,updateUserError, deleteUser, deleteUserSuccess, deleteUserError } from './userList.slice';

export const fetchAddUserSaga = function* (action) {
    try {
        const res = yield AdminService.addUser(action.payload);
        yield put(getUserList());
        yield put(closeModal());
        yield put({
            type: addUserSuccess.type,
            payload: res.data
        });
    } catch (e) {
        yield put({
            type: addUserError.type,
            payload: "Add user action failed"
        });
    }
}

export const fetchUpdateUserSaga = function* (action) {
    try {
        const res = yield AdminService.updateUser(action.payload);
        yield put(getUserList());
        yield put(closeModal());
        yield put({
            type: updateUserSuccess.type,
            payload: res.data
        });
    } catch (e) {
        yield put({
            type: updateUserError.type,
            payload: "Update user action failed"
        });
    }
}

export const fetchDeleteUserSaga = function* (action) {
    try {
        const res = yield AdminService.deleteUser(action.payload);
        yield put(getUserList());
        yield put(closeModal());
        yield put({
            type: deleteUserSuccess.type,
            payload: res.data
        });
    } catch (e) {
        yield put({
            type: deleteUserError.type,
            payload: "Delete action failed"
        });
    }
}

export const fetchUserListSaga = function* () {
    try {
        const res = yield AdminService.getUserList();
        yield put({
            type: getUserListSuccess.type,
            payload: res.data
        });
    } catch (e) {
        yield put({
            type: getUserListError.type,
            payload: "Userlist failed to fetch"
        });
    }
}

export const fetchAddUserWatch = function* () {
    yield takeEvery(addUser, fetchAddUserSaga);
}

export const fetchUpdateUserWatch = function* () {
    yield takeEvery(updateUser, fetchUpdateUserSaga);
}

export const fetchDeleteUserWatch = function* () {
    yield takeEvery(deleteUser, fetchDeleteUserSaga);
}

export const fetchUserListWatch = function* () {
    yield takeEvery(getUserList, fetchUserListSaga);
}