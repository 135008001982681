import { createSlice } from "@reduxjs/toolkit";
import { ApiStatus } from 'common/constant';

export const initialAdminIndependentFitlerState = {
    addAdminIndependentPartFilterModal: {
        isDialogOpen: false,
        mode: '',
        data: null
    },
    list: [],
    status: ''
  };

export const adminIndependentFilterListSlice = createSlice({
    name: "adminIndependentFilterListSlice",
    initialState: initialAdminIndependentFitlerState,
    reducers:{
        getIndependentPartFilterList: (state) => {
            state.status = ApiStatus.loading;
        },
        getIndependentPartFilterListSuccess: (state, action) => {
            state.list = action.payload;
            state.status = ApiStatus.success;
        },
        getIndependentPartFilterListError: (state, action) => {
            state.list = action.payload;
            state.status = ApiStatus.error;
        },
        updateIndependentPartFilterModal:(state, action) => {
            state.addAdminIndependentPartFilterModal.isDialogOpen = true;
            state.addAdminIndependentPartFilterModal.mode = action.payload.mode;
            state.addAdminIndependentPartFilterModal.data = action.payload.data;
        },
        closeIndependentPartFilterModal:(state, action) => {
            state.addAdminIndependentPartFilterModal.isDialogOpen = false;
            state.addAdminIndependentPartFilterModal.mode = '';
            state.addAdminIndependentPartFilterModal.data = null;
        },
    },
})

export const { getIndependentPartFilterList , getIndependentPartFilterListSuccess , getIndependentPartFilterListError , updateIndependentPartFilterModal , closeIndependentPartFilterModal} = adminIndependentFilterListSlice.actions;

export default adminIndependentFilterListSlice.reducer;