import { takeEvery, put } from '@redux-saga/core/effects';
import PartFiltersService from 'service/partFilters.service';
import { getPartFilterList , getPartFilterListSuccess , getPartFilterListError } from './partFiltersList.slice';

export const fetchPartFilterListSaga = function* () {
    try {
        const res = yield PartFiltersService.getPartFilterList();
        yield put({
            type: getPartFilterListSuccess.type,
            payload: res.data
        });
    } catch (e) {
        yield put({
            type: getPartFilterListError.type,
            payload: "Partlist failed to fetch"
        });
    }
}

export const fetchPartFilterListWatch = function* () {
    yield takeEvery(getPartFilterList, fetchPartFilterListSaga);
}