import { takeEvery, put } from '@redux-saga/core/effects';
import { getProductPageList, getProductPageListSuccess, getProductPageListError, updateProduct, updateProductSuccess, updateProductError, closeProductModal } from './productPageList.slice';
import ProductPageListService from 'service/productPageList.service'

export const fetchUpdateProductSaga = function* (action) {
    try {
        const res = yield ProductPageListService.updateProductPage(action.payload);
        yield put(getProductPageList());
        yield put(closeProductModal());
        yield put({
            type: updateProductSuccess.type,
            payload: res.data
        });
    } catch (e) {
        yield put({
            type: updateProductError.type,
            payload: "Update Product action failed"
        });
    }
}

export const fetchProductPageListSaga = function* () {
    try {
        const res = yield ProductPageListService.getProductPageList();
        yield put({
            type: getProductPageListSuccess.type,
            payload: res.data
        });
    } catch (e) {
        yield put({
            type: getProductPageListError.type,
            payload: e
        })
    }
}

export const fetchUpdateProductWatch = function* () {
    yield takeEvery(updateProduct, fetchUpdateProductSaga);
}
export const fetchProductPageListWatch = function* () {
    yield takeEvery(getProductPageList, fetchProductPageListSaga);
}