import { createSlice } from '@reduxjs/toolkit';
import { ApiStatus } from 'common/constant';

export const initialCatalogueSettingState = {
    organizationInfo: {},
    filterLabel:[],
    status: ''
};

export const catalogueSettingSlice = createSlice({
  name: 'catalogueSettingSlice',
  initialState: initialCatalogueSettingState,
  reducers: {
    postOrganization: (state) => {
      state.status = ApiStatus.loading;
    },
    postOrganizationSuccess: (state, action) => {
        state.status = ApiStatus.success;
        state.organizationInfo = action.payload;
    },
    postOrganizationError: (state, action) => {
        state.status = ApiStatus.error;
        state.organizationInfo = action.payload;
    },
    resetOrganizationStatus: (state) => {
      state.status = ApiStatus.loading;
    },
    getFilterLabel: (state) => {
      state.status = ApiStatus.loading;
    },
    getFilterLabelSuccess: (state, action) => {
        state.status = ApiStatus.success;
        state.filterLabel = action.payload;
    },
    getFilterLabelError: (state, action) => {
        state.status = ApiStatus.error;
        state.filterLabel = action.payload;
    },
  }
});

export const { postOrganization, postOrganizationSuccess, postOrganizationError, getFilterLabel, getFilterLabelSuccess, getFilterLabelError, resetOrganizationStatus } = catalogueSettingSlice.actions;

export default catalogueSettingSlice.reducer;