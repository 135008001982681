import { Outlet, Navigate } from 'react-router-dom'
import { useEffect, useState } from 'react';

const PrivateRoutes = () => {
    const [state, setState] = useState(true);

    useEffect(() => {
        const authToken = localStorage.getItem('adminuser');
        if (!authToken || authToken === 'undefined' || authToken === 'null') {
            setState(false)
        }
        else {
            setState(true)
        }
    }, [state])

    return (
        state ? <Outlet /> : <Navigate to="/admin/login" />
    )
}

export default PrivateRoutes
