import axiosInstance from 'service/api';

export default class filterStackService {

    static updatefilterStack(values) {
        const params = {...values};
        const body = {
            collectionName: 'master_lookups',
            data: params,
            condition: {_id: values._id}
        }
        console.log(body);
        const endPoint = `${window.config.updateFilterStackData}`;
        return axiosInstance.post(endPoint, body);
    }

    static updateDraggableFilterStack(values) {
        const params = [...values];
        const body = {
            collectionName: 'master_lookups',
            data: params,
        }
        console.log(body);
        const endPoint = `${window.config.updateDraggableFilterStack}`;
        return axiosInstance.post(endPoint, body);
    }

}