import { takeLatest, put } from '@redux-saga/core/effects';
import {
    getProductDetails,
    getProductDetailsSuccess,
    getProductDetailsError,
    getProductMake,
    getProductMakeSuccess,
    getProductMakeError,
    getProductSpecification,
    getProductSpecificationSuccess,
    getProductSpecificationError,
} from './product.slice';
import ProductService from 'service/product.service';

export const fetchProductDetails = function* (action) {
    try {
        const res = yield ProductService.getProductInfo(action.payload);
        yield put({
            type: getProductDetailsSuccess.type,
            payload: res.data
        });
    } catch (e) {
        yield put({
            type: getProductDetailsError.type,
            payload: e
        });
    }
}

export const fetchProductMake = function* (action) {
    try {
        const res = yield ProductService.getProductMake(action.payload);
        yield put({
            type: getProductMakeSuccess.type,
            payload: res.data
        });
    } catch (e) {
        yield put({
            type: getProductMakeError.type,
            payload: e
        });
    }
}

export const fetchProductSpecification = function* (action) {
    try {
        const res = yield ProductService.getProductSpecification(action.payload);
        yield put({
            type: getProductSpecificationSuccess.type,
            payload: res.data
        });
    } catch (e) {
        yield put({
            type: getProductSpecificationError.type,
            payload: e
        });
    }
}

export const fetchProductDetailsWatch = function* () {
    yield takeLatest(getProductDetails, fetchProductDetails);
}

export const fetchProductMakeWatch = function* () {
    yield takeLatest(getProductMake, fetchProductMake);
}

export const fetchProductSpecificationWatch = function* () {
    yield takeLatest(getProductSpecification, fetchProductSpecification);
}