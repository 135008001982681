import { takeEvery, put } from '@redux-saga/core/effects';
import PartDetailsService from 'service/partDetails.service';
import { getProvider, getProviderSuccess, getProviderError } from './part.slice';

export const fetchPartSaga = function* () {
    try {
        const res = yield PartDetailsService.getProvider();
        yield put({
            type: getProviderSuccess.type,
            payload: res.data
        });
    } catch (e) {
        yield put({
            type: getProviderError.type,
            payload: "get part action failed"
        });
    }
}

export const fetchPartWatch = function* () {
    yield takeEvery(getProvider, fetchPartSaga);
}

