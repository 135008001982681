import { createSlice } from "@reduxjs/toolkit";
import { ApiStatus } from 'common/constant';

export const initialUserForgotPasswordState = {
    userForgot:[],
    status: ''
  };

export const userForgotSlice = createSlice({
    name: "userForgot",
    initialState: initialUserForgotPasswordState,
    reducers:{
        sendForgotPasswordMail:(state) => {
            state.status = ApiStatus.loading;
        },
        sendForgotPasswordMailSuccess:(state, action) => {
            state.userForgot = action.payload;
            state.status = ApiStatus.success;
        },
        sendForgotPasswordMailError:(state, action) => {
            state.userForgot = action.payload;
            state.status = ApiStatus.error;
        },
        resetStatus:(state) => {
            state.status = ApiStatus.loading;
        },
    },
})

export const { sendForgotPasswordMail, sendForgotPasswordMailSuccess, sendForgotPasswordMailError, resetStatus } = userForgotSlice.actions;

export default userForgotSlice.reducer;