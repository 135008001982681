import { createTheme } from '@mui/material/styles';
import { pink, grey, yellow, } from '@mui/material/colors'

const DemoTheme = createTheme({
  palette: {
    primary: yellow,
    secondary: pink,
    text: {
      primary: '#2f5ca2',
      secondary: grey[700],
      name: 'second',
    },
    signup: {
      buttonColor: '#2f5ca2 !important',
      subcaptchaColor:'white',
      captchaInputRoot:'#ffffff',
      formLabelColor:'white !important',
      formBg:'#f5f5f5',
      inputRootBgColor:'#ffffff',
      selectBgColor:'#ffffff',
      cityBgColor:'#ffffff',
      btnSignupColor:'white !important',
      dialogBgColor:'#2f5ca2 !important',
      closeIconColor:'white !important',
      fontControlColor:'#2f5ca2 !important'
    },
    login:{
      captcha:'#ffffff',
      subCaptcha:'white',
      input:'#ffffff',
      link:'#2f5ca2 !important',
      submitButtonColor:'#2f5ca2 !important'
    },
    forgotpassword: {
      buttonColor:'#2f5ca2',
      dividerLabelColor:'#2f5ca2',
      link:'#2f5ca2',
      submitButtonColor:'#2f5ca2 !important'
    },
    adminforgotpassword:{
      formContainerbgColor:'#fafbfc',
      color:'#2f5ca2',
      dividerColor:"1px solid #2f5ca2"
    },
    resetpassword: {
      buttonColor:'#2f5ca2',
      dividerLabelColor:'#2f5ca2',
      link:'#2f5ca2 !important',
      submitButtonColor:'#2f5ca2 !important',
      border:'solid thin #2f5ca2',
      color:'#2f5ca2'
    },
    addUser:{
      containerBgColor:'#2f5ca2',
      dropdownControlColor:'black',
      dialogTitleBgColor:'#2f5ca2',
      dialogTitleColor:'white',
      muiButtonBgColor:'#2f5ca2 !important'
   },
   adminLogin:{
    formContainerBgColor:'#fafbfc'
   },
   checkbox: {
    color: '#2f5ca2 !important'
  },
   admin:{
     miniDrawerBg:'#2f5ca2',
     menuIconColor: '#2f5ca2 !important',
     activeColor: '#2f5ca2'
   },

   partDetails: {
    columnHeader: '#2f5ca2'
  },
  partImages: {
    columnHeader: '#2f5ca2'
  },
  partFilter: {
    columnHeader: '#2f5ca2'
  },
  oeNumber: {
    columnHeader: '#2f5ca2'
  },
  subParts: {
    columnHeader: '#2f5ca2'
  },
  catalogSetting: {
    bgColor: '#2f5ca2',
    lightBgColor: '#ADD8E6',
    border:'2px solid #2f5ca2'
  },
  productPageList:{
    columnHeader: '#2f5ca2'
  },
  groupList:{
    columnHeader: '#2f5ca2'
  },
  filterLabel:{
    columnHeader: '#2f5ca2'
  },
  filterValues:{
    columnHeader: '#2f5ca2'
  },
  specification:{
    columnHeader: '#2f5ca2'
  },
  
  
    catalogItem:{
      itemCard:'#fff',
      itemCardHover:'#2f5ca2',
      itemCardHoverBorder:'none',
      itemNameColor:'#fff',
      itemNameBackgroundColor:'#97d1cc',
      productCardFooterHoverColor:'none',
      itemCardBgColor:'none',
      itemCardColor:'none',
      itemCardHoverColor:'none'
    },
    filterElement:{
        checked:'#2f5ca2 !important',
        filterSelectBackgroundColor:'#97d1cc',
        filterSelectColor:'#fff'
    },
    Header:{
      navbarBackgroundColor:'rgba(0,0,0,.9)',
      menuItem:'#ffffff',
      menuItemHover:'#2f5ca2',
      menuChildBackgroundColor:'#000000d1',
      searchInput:'#495057',
      searchInputBorder:'1px solid #2f5ca2'
    },
    sideBar:{
      filterHeaderBackground:'#2f5ca2',
      filterHeaderColor:'#fff',
      filterHeaderBorderTop:'#2f5ca2',
      filterHeaderBefore:'rgba(255, 51, 0,0.09)  !important',
      filterContentHeaderText:'#2f5ca2',
      filterContentHighlightBg:'#dae7e8 !important',
      filterContentHighlightColor:'black !important',
      filterContentWrapper:'white',
      filterContentScrollThumb:'#2f5ca2',
    },
    productDescription:{
      breadCrumb:'#2f5ca2',
      gridRowBorder:'1px solid #eaeaea',
      gridColumnFirstTypeBorder:'solid #eaeaea',
      gridColumnLastTypeBorder:'1px solid #eaeaea',
      gridColumnItemWrapperBorder:'1px solid #dedddd',
      gridColumnItemImgBorder:'1px solid #eaeaea',
      gridColumnItemContainerBorder:'1px solid #eaeaea',
      gridColumnItemContentBorder:'1px solid black',
      gridColumnHeaderHeighlightBg:'#2f5ca2 !important',
      gridColumnHeaderHeighlightColor:'white !important',
      gridColumnHeaderHeighlightHoverColor:'white !important',
      gridColumnHeaderHeighlightHoverBg:'#2f5ca2 !important',
      gridColumnHeaderLinkBg:'white',
      gridColumnHeaderLinkColor:'#495057',
      gridColumnHeaderLinkBorder:'1px solid #dee2e6',
      gridColumnHeaderLinkBackground:'#f8f9fa',
      gridColumnTableDataColor:'#000',
      gridColumnTableDataBorder:'0.1px solid black',
      gridColumnTableHeadBorder:'0.1px solid black',
      gridColumnTableHeadBackground:'#2f5ca2',
      gridColumnTableHeadColor:'white',
      gridColumnTableContentBorder:'0.1px solid black !important',
      gridColumnThumbPrevNext:'#2f5ca2 !important',
      gridColumnTableDataFirstChild:'none',
      gridColumnTableDataLastChild:'none',
      specificationTableTableRow:'none',
      gridBtnBg:'#2f5ca2',
      gridBtnColor:'white',
      btnSearchColor:'white',
      btnSearchBg:'#2f5ca2',
      serachInputBorder:'1px solid #ff3300',
      InnerImageZoomContainerBg:'#2f5ca2',
      imageCardHoverBorderColor:'2px solid #2f5ca2',
      onClickCloseBg:'#2f5ca2 !important'
    },
    productList:{
      productCardHeader:'#97d1cc',
      productCardFooter:'none',
      productCardFooterColor:'none',
      breadCrumb:'#2f5ca2',
      mainContainerDataViewHeaderBg:'#fff',
      mainContainerDataViewHeaderColor:'#495057',
      mainContainerDataViewHeaderBorder:'1px solid #e9ecef',
      mainContainerDataViewContentColor:'#000000',
      mainContainerDataViewContentBg:'#f1f6f8',
      headerGridHighlightBorder:'#2f5ca2',
      headerGridHighlightBackground:'#2f5ca2',
      headerGridHighlightHoverBorder:'#2f5ca2',
      headerGridHighlightHoverBackground:'#2f5ca2',
      shareBtnBackground:'transparent',
      shareBtnColor:'#2f5ca2',
      shareIconColor:'#2f5ca2',
      productDescriptionBg:'#000000',
      productDescriptionColor:'#ffffff',
      productNameBg:'#97d1cc',
      productNameColor:'#fff',
      productListFooterHeight: '71px',
      productListfooterDisplay: 'flex',
      productListTitleDisplay: 'block',
      productListTitleHeight: 'auto',
      productListItemBackground:'#dae7e8',
      productListDetailBorder:'1px solid #fff',
      productListDetailBackground:'#fff',
      viewMoreLinkColor:'white',
      viewMoreLinkBackground:'#000 !important',
      viewMoreLinkBorder:'1px solid #000 !important',
      gridColumnTableDataColor:'black',
      gridColumnTableDataBorder:'0.1px solid black',
      gridColumnTableDataFirstChild:'0.1px solid black',
    },
    BreadCrumb:{
      textParagraphColor:'#ffffff',
      linkStyle:'#ffffff',
      breadCrumbColor:'#ffffff'
    },
    Loader:{
      loaderContainerBgColor:'rgba(0,0,0,0.2)'
    },
    emailVerify:{
      btnStyle:'#2f5ca2 !important'
    },
    Header:{
       navBarBgColor:'white',
       menuContainerBgColor:'rgba(0,0,0,.9)',
       menuContainerColor:'white',
       menuIconBgColor:'white',
       menuItemColor:'#000',
       menuItemHoverColor:'#000',
       menuChildBackground:'#000000d1',
       mobileMenuChildContainerBackground:'#ffffff !important',
       menuChildContainerBackground:'#f5f5f5',
       searchInputBorder:'1px solid #2f5ca2',
       searchInputColor:'#495057',
       searchButtonBorder:'1px solid #2f5ca2',
       searchButtonBgColor:'#fff'
    },
    toggleImg:{
      mobileFilterLogo:'#2f5ca2'
    },
    toggleSearchIcon:{
      toggleSearchIconColor:'1px solid #2f5ca2'
    },
    SocialShare:{
      messagePositionBgColor:'#2f5ca2'
    },
    background: {
      default: '#eee',
      paper: '#fff',
    },
    divider: grey[300],
  },
  spacing: 8,
  typography: {
    fontFamily: [
      '"Hiragino Sans"',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      'Helvetica',
      'Arial',
      'sans-serif',
    ].join(','),
  },
  components: {
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {},
    },
    MuiTextField: {
      defaultProps: {
        variant: 'filled',
      },
    },
  },
});

export default DemoTheme;
