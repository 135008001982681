import { createSlice } from '@reduxjs/toolkit';
import { ApiStatus } from 'common/constant';

export const initialProductListState = {
    productDetails: {},
    productMake: '',
    productSpecification: {},
    status: ''
};

export const productSlice = createSlice({
  name: 'productSlice',
  initialState: initialProductListState,
  reducers: {
    getProductDetails: (state) => {
      state.status = ApiStatus.loading;
      state.productDetails = {};
      state.productMake = '';
      state.productSpecification = {};
    },
    getProductDetailsSuccess: (state, action) => {
        state.status = ApiStatus.success;
        state.productDetails = action.payload[0];
    },
    getProductDetailsError: (state, action) => {
        state.status = ApiStatus.error;
    },
    getProductMake: (state) => {
      state.status = ApiStatus.loading;
    },
    getProductMakeSuccess: (state, action) => {
        state.status = ApiStatus.success;
        state.productMake = action.payload[0];
    },
    getProductMakeError: (state, action) => {
        state.status = ApiStatus.error;
        state.productMake = action.payload;
    },
    getProductSpecification: (state) => {
      state.status = ApiStatus.loading;
    },
    getProductSpecificationSuccess: (state, action) => {
        state.status = ApiStatus.success;
        state.productSpecification = action.payload[0];
    },
    getProductSpecificationError: (state, action) => {
        state.status = ApiStatus.error;
        state.productSpecification = action.payload;
    },
  }
});

export const {
    getProductDetails,
    getProductDetailsSuccess,
    getProductDetailsError,
    getProductMake,
    getProductMakeSuccess,
    getProductMakeError,
    getProductSpecification,
    getProductSpecificationSuccess,
    getProductSpecificationError
} = productSlice.actions;

export default productSlice.reducer;
