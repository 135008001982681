import { takeLatest, put } from '@redux-saga/core/effects';
import {
    getInitialCategory,
    getInitialCategorySuccess,
    getInitialCategoryError,
    getAllCategory,
    getAllCategorySuccess,
    getAllCategoryError
} from './category.slice';
import CategoryService from 'service/category.service';

export const fetchInitialCategorySaga = function* () {
    try {
        const res = yield CategoryService.getInitialCategory();
        yield put({
            type: getInitialCategorySuccess.type,
            payload: res.data
        });
    } catch (e) {
        yield put({
            type: getInitialCategoryError.type,
            payload: e
        });
    }
}

export const fetchAllCategorySaga = function* () {
    try {
        const res = yield CategoryService.getAllCategory();
        yield put({
            type: getAllCategorySuccess.type,
            payload: res.data
        });
    } catch (e) {
        yield put({
            type: getAllCategoryError.type,
            payload: e
        });
    }
}

export const fetchInitialCategoryWatch = function* () {
    yield takeLatest(getInitialCategory, fetchInitialCategorySaga);
}

export const fetchAllCategoryWatch = function* () {
    yield takeLatest(getAllCategory, fetchAllCategorySaga);
}