import { createSlice } from '@reduxjs/toolkit';
import { ApiStatus } from 'common/constant';

export const initialProductListState = {
    data: {},
    status: '',
    searchText: ''
};

export const productListSlice = createSlice({
  name: 'productListSlice',
  initialState: initialProductListState,
  reducers: {
    getProductList: (state) => {
      state.status = ApiStatus.loading;
      state.data = {};
    },
    getProductListSuccess: (state, action) => {
        state.status = ApiStatus.success;
        state.data = action.payload;
    },
    getProductListError: (state, action) => {
        state.status = ApiStatus.error;
        state.data = action.payload;
    },
    getProductListByFilters: (state) => {
      state.status = ApiStatus.loading;
    },
    getProductListBySearch: (state) => {
      state.status = ApiStatus.loading;
    },
    updateSearchText: (state, action) => {
      state.searchText = action.payload;
    }
  }
});

export const {
    getProductList,
    getProductListSuccess,
    getProductListError,
    getProductListByFilters,
    getProductListBySearch,
    updateSearchText
} = productListSlice.actions;

export default productListSlice.reducer;
