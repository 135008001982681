import axiosInstance from 'service/api';
import Constant from 'common/constant';

export default class CategoriesService {
    static getGroupList() {
        const endPoint = `${window.config.genericGet}?collectionName=${window.config['collections']['masterLookup']}&search={'lookup_id':'${Constant.masterLookup.classification.name}'}`;
        return axiosInstance.get(endPoint);
    }

    static addGroup(values) {
        const body = {
            collectionName: 'master_lookups',
            lookup_id: "group_classification",
            data: [values],
            condition: [{ _id: values._id }]
        }
        const endPoint = `${window.config.updateGroupDocument}`;
        return axiosInstance.post(endPoint, body);
    }

    static updateGroup(values) {
        const body = {
            collectionName: 'master_lookups',
            lookup_id: "group_classification",
            data: [values],
            condition: [{ _id: values._id }]
        }
        const endPoint = `${window.config.UpdateGroupListDocument}`;
        return axiosInstance.post(endPoint, body);
    }

    static deleteGroup(values) {
        console.log("values:", values)
        const body = {
            collectionName: 'master_lookups',
            lookup_id: "group_classification",
            data: [values],
            delete: [{ _id: values._id }]

        }
        const endPoint = `${window.config.deleteGroupDocument}`;
        return axiosInstance.post(endPoint, body);
    }

    // etc

    static addFilterLabel(values) {
        console.log(values);
        const body = {
            "collectionName": 'master_lookups',
            "data": [{
                "lookup_id": values.lookup_id,
                "types": []
            }],
            "orgdata": {
                "parent": values.parent
            }
        }
        const endPoint = `${window.config.InsertLabel}`;
        return axiosInstance.post(endPoint, body);
    }

    static updateFilterLabel(values) {
        const body = {
            "collectionName": 'master_lookups',
            "data": [{
                "id": values._id,
                "lookup_id": values.lookup_id,
                "types": []
            }],
            "orgdata": {
                "parent": values.parent
            }
        }
        console.log(body)
        const endPoint = `${window.config.UpdateLabel}`;
        return axiosInstance.post(endPoint, body);
    }

    static deleteFilterLabel(values) {
        console.log(values._id['$oid']);
        const body = {
            "collectionName": 'master_lookups',
            "data": [{
                "_id": values._id
            }]
        }
        const endPoint = `${window.config.DeleteLabel}`;
        return axiosInstance.post(endPoint, body);
    }

    // end
}
