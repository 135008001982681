import { takeLatest, put } from '@redux-saga/core/effects';
import {
    getProductListSuccess,
    getProductListError,
    getProductList,
    getProductListByFilters,
    getProductListBySearch
} from './productList.slice';
import ProductListService from 'service/productList.service';

export const fetchProductListSaga = function* (action) {
    try {
        const res = yield ProductListService.getProductsList(action.payload);
        yield put({
            type: getProductListSuccess.type,
            payload: res
        });
    } catch (e) {
        yield put({
            type: getProductListError.type,
            payload: e
        });
    }
}

export const fetchProductListByFiltersSaga = function* (action) {
    try {
        const res = yield ProductListService.getProductsListByFilters(action.payload);
        yield put({
            type: getProductListSuccess.type,
            payload: res
        });
    } catch (e) {
        yield put({
            type: getProductListError.type,
            payload: e
        });
    }
}

export const fetchProductListBySearchSaga = function* (action) {
    try {
        const res = yield ProductListService.getProductsListBySearch(action.payload);
        yield put({
            type: getProductListSuccess.type,
            payload: res
        });
    } catch (e) {
        yield put({
            type: getProductListError.type,
            payload: e
        });
    }
}

export const fetchProductListWatch = function* () {
    yield takeLatest(getProductList, fetchProductListSaga);
}

export const fetchProductListByFiltersWatch = function* () {
    yield takeLatest(getProductListByFilters, fetchProductListByFiltersSaga);
}

export const fetchProductListBySearchWatch = function* () {
    yield takeLatest(getProductListBySearch, fetchProductListBySearchSaga);
}