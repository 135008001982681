import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import { makeStyles, createStyles } from "@mui/styles";
import {
    Groups,
    Analytics,
    Settings,
    HowToReg,
    ChevronLeft,
    ChevronRight,
    Contrast,
    Logout
} from '@mui/icons-material';
import { getInitialCategory } from 'redux/category/category.slice';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import { getUserList } from 'redux/users/userList.slice';
import { getUserCountries } from 'redux/adminLogin/adminLogin.slice';
import { updateUser } from 'redux/users/userList.slice';
import { updateFilterData } from 'redux/users/userList.slice';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
// import ContrastIcon from '@mui/icons-material/Contrast';
// import { useHistory } from 'react-router-dom';
import {
    Box,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    IconButton,
    Avatar,
    Button
} from '@mui/material';
import ProfileIcon from './profile';
import { getOrganization } from "redux/organization/organization.slice";



const drawerWidth = 240;

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(9)} + 1px)`,
    },
});

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        color: 'white',
        boxSizing: 'border-box',
        overflow: 'unset',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);


const useStyles = makeStyles((theme) => {
    return createStyles({
        drawerHeader: {
            display: 'flex',
            minHeight: '50px',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '5px 15px 15px 15px',
        },
        handlerIcon: {
            position: 'absolute !important',
            right: '-10px',
            padding: '0px',
            top: '65px',
            // background: theme.palette.admin.menuIconColor,
            pointerEvents: 'none',
            // cursor: 'pointer',
            width: '20px',
            height: '20px',
            // '&:hover': {
            //     background: 'orange'
            // }
        },
        drawerContainer: {
            '& .MuiDrawer-paper': {
                border: 0
            },
            '& .MuiList-root': {
                overflow: 'hidden'
            }
        },
        icon: {
            marginRight: "10px",
            marginLeft: "30px",
            width: '20px',
            height: '20px',
        },
        group: {
            backgroundColor: "#E0CC54",
            borderRadius: "5px",
            display: "flex",
            alignItems: "center",
            color: "black",
            margin: "0px 10px",
        },
        noAction: {
            pointerEvents: 'none'
        },
        menuIconColor: {
            color: ({ colorState }) => colorState,
        },
        menuLabel: {
            color: ({ colorState }) => colorState,
        },
        menuIcon: {
            minWidth: '40px !important'
        },
        menuList: {
            marginTop: '20px',
            paddingTop: '0 !important',
            paddingBottom: '0 !important'
        },
        indexMenuList: {
            paddingTop: '0 !important',
            paddingBottom: '0 !important'
        },
        mainContainer: {
            width: `calc(100% - 240px)`,
            overflow: 'auto',
            position: 'relative',
            height: '100%',
            background: '#f5f5f5'
        },
        mainHeader: {
            height: '50px',
            background: theme.palette.admin.menuIconColor,
            borderTopLeftRadius: '25px',
            borderTopRightRadius: '25px',
            background: ({ colorState }) => colorState,
        },
        routeContainer: {
            position: 'absolute',
            top: 50,
            bottom: 0,
            left: 0,
            right: 0,
            padding: '10px 30px'
        },
        active: {
            backgroundImage: ({ colorState }) => `linear-gradient(to right, ${colorState}, white)`,
            borderLeft: ({ colorState }) => `4px solid ${colorState}`,
        },
        listParent: {
            // paddingTop: '20px !important'
        },
        Buttons: {
            color: 'white !important'
        }
    })
}
)

export default function MiniDrawer() {
    const { t } = useTranslation(['common']);
    const dispatch = useDispatch();
    const location = useLocation();
    const [open, setOpen] = React.useState(true);
    // const classes = useStyles();
    const navigate = useNavigate();
    const { initialCategory: masterLookups = [] } = useSelector((state) => state.categories);
    const [state, setState] = useState(false);

    const { organizationDetail } = useSelector((state) => state);
    const colorData = process.env.REACT_APP_PROJECT_NAME || window.config.tenant == 'amrep' ? "#007a49" : process.env.REACT_APP_PROJECT_NAME || window.config.tenant == 'demo' ? "#e0cc54" : "";
    const colorState = organizationDetail.data.color ? organizationDetail.data.color : colorData;
    const classes = useStyles({ colorState });

    const [userMenuControl, setUserMenuControl] = useState([]);

    const {
        list: adminUserList
    } = useSelector((state) => state.userData);
    const { isDialogOpen, mode, data } = useSelector(state => state.userData.addUserModal);
    const [orgData, setOrgData] = useState([]);
    const [userState, setUserState] = useState(false);
    const [id, updateId] = useState("");
    // dispatch(updateUser(params));

    useEffect(() => {
        if (!masterLookups.length) {
            dispatch(getInitialCategory());
        }
    }, []);

    useEffect(() => {
        dispatch(getUserList());
        dispatch(getUserCountries());
    }, [])

    useEffect(() => {
        if (adminUserList) {

            const userExist = adminUserList.filter((userData, parentIndex) => {
                const checkedInfo = userData?.rows?.filter((info, childIndex) => {
                    if (info.label == 'users' && info.checked == true) {
                        return info;
                    }
                })
                if (adminUserList[parentIndex]['rows'].includes(checkedInfo[0])) {
                    return adminUserList[parentIndex]
                }
            })

            adminUserList.map((userData, index) => {
                const { rows } = userData;
                if (userData?.email == localStorage.username) {
                    setUserMenuControl(userData.rows)
                }

                //     // if(adminUserList[index]['rows'][i]['label'] == "users" && adminUserList[index]['rows'][i]['checked'] == true){
                //     //     console.log("true")
                //     // }

                //     userExist = userData?.rows?.filter((info, index) => {
                //         if (info.label == 'users' && info.checked == true) {
                //             return info;
                //         }
                //     })

                //     // console.log(userData['rows'][index]);

                //     // const newData = rows?.filter((info, ind) => {
                //     //     if (info.label == 'users') {
                //     //         console.log("entered")
                //     //         return info;
                //     //     }
                //     // })
                //     // console.log(newData);

                // if (userExist?.length == 1) {
                //     console.log(userExist);
                // console.log("userExist", userData);
                // let deepCopyOfUserExist = adminUserList[0] ? JSON.parse(JSON.stringify(adminUserList[0])) : [];
                // deepCopyOfUserExist?.rows?.filter((info, index) => {
                //     if (info.label == 'users' && info.checked != true) {
                //         info.checked = true;
                //         return info;
                //     }
                // })
                // console.log(deepCopyOfUserExist);
                // if (!userExist?.length && index == 0) {
                //     console.log("entered");
                //     dispatch(updateUser(deepCopyOfUserExist));
                // }
                // } else {

                // }
            })

            if (userExist?.length == 1) {
                let deepCopyOfUserExist = userExist ? JSON.parse(JSON.stringify(userExist)) : [];
                deepCopyOfUserExist?.rows?.filter((info, index) => {
                    if (info.label == 'users' && info.checked != true) {
                        setUserState(true);
                        info.checked = true;
                        return info;
                    }
                })
                // console.log(deepCopyOfUserExist);
                // if (userState) {
                //     console.log("entered");
                //     setUserState(false);
                // dispatch(updateUser(deepCopyOfUserExist));
                // }
            }
            else {
                console.log(userExist);
            }
        }
    }, [adminUserList]);

    useEffect(() => {
        dispatch(getOrganization());
    }, []);

    const handleDrawer = () => {
        setOpen(!open);
    };

    const navigateTo = (routePath) => {
        navigate(routePath);
    }

    useEffect(() => {
        const filteredUserData = userMenuControl.filter((info, index) => {
            if (info.checked) {
                return info;
            }
        })
        setOrgData(filteredUserData);
    }, [userMenuControl])

    const LoadLogo = (data) => {

        switch (data.label) {
            case 'partdetails':
                return <Contrast className={classes.menuIconColor} />
            case 'registereduser':
                return <HowToReg className={classes.menuIconColor} />
            case 'cataloguesettings':
                return <Settings className={classes.menuIconColor} />
            case 'users':
                return <Groups className={classes.menuIconColor} />
            case 'Analytics':
                return <Analytics className={classes.menuIconColor} />

            default:
                break;
        }
    }

    useEffect(() => {
        userMenuControl?.map((userInfo, index) => {
            if (!userInfo.checked) {
                if (window.location.hash == `#/admin/${userInfo.label}`) {
                    navigate(-1)
                }
            }
        })
    }, [window.location.href])


    useEffect(() => {
        switch (process.env.REACT_APP_PROJECT_NAME || window.config.tenant) {
            case "amrep":
                updateId(process.env.AMREP_ANALYTICS || "i58cufvfrf")
                break;
            case "demo":
                updateId(process.env.DEMO_ANALYTICS || "i9ukc2zp5s")
                break;
            default:
                break;
        }
    }, [orgData])

    const list = () => (
        <Box
            role="presentation"
            className={classes.drawerItemsContainer}
        >
            {
                orgData?.map((userInfo, index) => {
                    return <List className={classes.listParent}>
                        {userInfo.checked && <ListItem onClick={() => { userInfo.label === 'Analytics' ? window.open(`https://clarity.microsoft.com/projects/view/${id}/dashboard?date=Last%203%20days`, "_blank") : navigateTo(userInfo.label === 'admin' ? `/${userInfo.label}` : `/admin/${userInfo.label}`) }} className={[classes.menuList, location.pathname === (userInfo.label === 'admin' ? `/${userInfo.label}` : `/admin/${userInfo.label}`) ? classes.active : '']}>
                            <ListItemButton>
                                <ListItemIcon className={classes.menuIcon}>
                                    {
                                        LoadLogo(userInfo)
                                    }
                                </ListItemIcon>
                                <div className={classes.menuLabel}>{userInfo.label == 'Analytics' ? <span style={{display:'flex', alignItems:'center'}}>{t(userInfo.label)}&nbsp;<OpenInNewIcon style={{ height: '20px', width: '20px' }} /></span> : t(userInfo.label)}</div>
                            </ListItemButton>
                        </ListItem>
                        }

                        {/* {userInfo.label == 'admin' && userInfo.checked && <ListItem onClick={() => { navigateTo('/admin') }} className={[classes.menuList, location.pathname === "/admin" ? classes.active : '']}>
                            <ListItemButton>
                                <ListItemIcon className={classes.menuIcon}><Contrast className={classes.menuIconColor} /></ListItemIcon>
                                <div className={classes.menuLabel}>{t("partdetails")}</div>
                            </ListItemButton>
                        </ListItem>
                        }
                        {
                            userInfo.label == 'registereduser' && userInfo.checked && <ListItem onClick={() => { navigateTo('registereduser') }} className={[classes.menuList, location.pathname === "/admin/registereduser" ? classes.active : '']}>
                                <ListItemButton>
                                    <ListItemIcon className={classes.menuIcon}><HowToReg className={classes.menuIconColor} /></ListItemIcon>
                                    <div className={classes.menuLabel}>{t("registeredUsers")}</div>
                                </ListItemButton>
                            </ListItem>
                        }
                        {
                            userInfo.label == 'cataloguesettings' && userInfo.checked && <ListItem onClick={() => { navigateTo('cataloguesettings') }} className={[classes.menuList, location.pathname === "/admin/cataloguesettings" ? classes.active : '']}>
                                <ListItemButton>
                                    <ListItemIcon className={classes.menuIcon}><Settings className={classes.menuIconColor} /></ListItemIcon>
                                    <div className={classes.menuLabel}>{t("catalogueSettings")}</div>
                                </ListItemButton>
                            </ListItem>
                        }
                        {
                            userInfo.label == 'users' && userInfo.checked && <ListItem onClick={() => { navigateTo('users') }} className={[classes.menuList, location.pathname === "/admin/users" ? classes.active : '']}>
                                <ListItemButton>
                                    <ListItemIcon className={classes.menuIcon}><Groups className={classes.menuIconColor} /></ListItemIcon>
                                    <div className={classes.menuLabel}>{t("adminUsers")}</div>
                                </ListItemButton>
                            </ListItem>
                        } */}
                    </List>
                })
            }
        </Box>
    );

    const loadHeader = () => {

        const handleRedirectToCatalog = () => {
            const targetUrl = "/";
            window.open(targetUrl, "_blank");
        };

        return (
            <header className={classes.mainHeader}>
                <div style={{ display: 'flex', justifyContent: 'right', alignItems: 'center' }}>
                    {/* <div style={{ marginRight: '20px', marginTop: '8px' }} onClick={() => {
                        navigateTo('/');
                    }}><Button className={classes.Buttons}>Redirect to Catalog</Button></div> */}
                    <div style={{ marginRight: '20px', marginTop: '8px' }}>
                        <Button className={classes.Buttons} onClick={handleRedirectToCatalog}>
                            Go to Catalog&nbsp;<OpenInNewIcon style={{ height: '20px', width: '20px' }} />
                        </Button>
                    </div>

                    <div style={{ marginRight: '20px', marginTop: '8px' }} onClick={() => {
                        localStorage.removeItem('adminuser');
                        window.location.reload();
                    }}><Button className={classes.Buttons}>Logout</Button></div>
                    {/* <div style={{ display: 'flex', justifyContent: 'right' }}>
                        <IconButton onClick={() => setState(!state)} style={{ position: 'absolute', right: '20px', top: '-3px' }}>
                            <Avatar style={{ backgroundColor: 'black', height: '40px', width: '40px' }}><PersonOutlineOutlinedIcon style={{ height: '30px', width: '30px' }} /></Avatar>
                        </IconButton>
                        {
                            state &&
                            <div style={{ position: 'absolute', top: '55px', zIndex: 9999, right: '5px' }}><ProfileIcon /></div>
                        }
                    </div> */}
                </div>
            </header>
        )
    }

    return (
        <Box sx={{ display: 'flex', height: '100%' }}>
            <Drawer
                variant="permanent"
                open={open}
                className={classes.drawerContainer}
                PaperProps={{
                    sx: {
                        overflow: "unset !important",
                        background: "white",
                        color: "orange",
                    }
                }}
            >
                <div className={classes.drawerHeader}>
                    {process.env.REACT_APP_PROJECT_NAME == "demo" ? (
                        <img
                            className="company-logo"
                            // src={organizationDetail?.data?.img ? organizationDetail?.data?.img: `${window.pathUrl}/assets/images/${process.env.REACT_APP_PROJECT_NAME}/logo.png` }
                            src={organizationDetail?.data.imgUrl ? organizationDetail?.data?.imgUrl : organizationDetail?.data?.img}
                            width="180px"
                            height="35px"
                        />
                    ) : (
                        <img
                            className="company-logo"
                            // src={`${window.pathUrl}/assets/images/${process.env.REACT_APP_PROJECT_NAME}/logo.png`}
                            // src={organizationDetail?.data?.img ? organizationDetail?.data?.img: `${window.pathUrl}/assets/images/${process.env.REACT_APP_PROJECT_NAME}/logo.png` }
                            src={organizationDetail?.data.imgUrl ? organizationDetail?.data?.imgUrl : organizationDetail?.data?.img}
                            width="155px"
                            height="50px"
                        />
                    )}

                    <IconButton onClick={handleDrawer} className={classes.handlerIcon}>
                        {open ? <ChevronLeft sx={{ color: 'white' }} fontSize="small" className={classes.noAction} style={{ backgroundColor: colorState, borderRadius: "10px" }} /> : <ChevronRight />}
                    </IconButton>
                </div>
                {list()}
            </Drawer>
            <div className={classes.mainContainer}>
                {loadHeader()}
                <div className={classes.routeContainer}>
                    <Outlet />
                </div>
            </div>
        </Box>
    );
}

