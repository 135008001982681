import React, { useState, useEffect } from 'react';
import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider as MuiThemeProvider } from '@mui/styles';
import SankarTheme from './sankar';
import VibroMechTheme from './vibromech';
import Amrep from './amrep';
import Demo from './demo';

const themeMap = {
  sankar: SankarTheme,
  vibromech: VibroMechTheme,
  amrep:Amrep,
  demo:Demo
};

export const ThemeContext = React.createContext((themeName) => {});

export function getThemeByName(theme) {
  return themeMap[theme];
}

const ThemeProvider = ({ children }) => {
  const [themeName, _setThemeName] = useState(process.env.REACT_APP_PROJECT_NAME);
  const [theme, setTheme] = useState(getThemeByName(themeName));

  useEffect(() => {
    setTheme(getThemeByName(themeName));
  }, [themeName]);

  return (
    <ThemeContext.Provider value={_setThemeName}>
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </MuiThemeProvider>
    </ThemeContext.Provider>
  )
}

export default ThemeProvider;
