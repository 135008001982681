import { takeLatest, put } from '@redux-saga/core/effects';
import {
    getSpecificationList,
    getSpecificationListSuccess,
    getSpecificationListError,
} from './specificationList.slice';
import ProductService from 'service/product.service';
import {
    closeSpecificationModal,
    addSpecificationSuccess,
    addSpecificationError,
    updateSpecificationSuccess,
    updateSpecificationError,
    deleteSpecificationSuccess,
    deleteSpecificationError,
    addSpecification,
    updateSpecification,
    deleteSpecification
} from './specificationList.slice';

export const fetchSpecificationList = function* (action) {
    try {
        const res = yield ProductService.getProductSpecificationList();
        yield put({
            type: getSpecificationListSuccess.type,
            payload: res.data
        });
    } catch (e) {
        yield put({
            type: getSpecificationListError.type,
            payload: e
        });
    }
}

export const fetchAddSpecificationSaga = function* (action) {
    try {
        const res = yield ProductService.addSpecification(action.payload);
        yield put(getSpecificationList());
        yield put(closeSpecificationModal());
        yield put({
            type: addSpecificationSuccess.type,
            payload: res.data
        });
    } catch (e) {
        yield put({
            type: addSpecificationError.type,
            payload: "Add Specification action failed"
        });
    }
}

export const fetchUpdateSpecificationSaga = function* (action) {
    try {
        const res = yield ProductService.updateSpecification(action.payload);
        yield put(getSpecificationList());
        yield put(closeSpecificationModal());
        yield put({
            type: updateSpecificationSuccess.type,
            payload: res.data
        });
    } catch (e) {
        yield put({
            type: updateSpecificationError.type,
            payload: "Update Specification action failed"
        });
    }
}

export const fetchDeleteSpecificationSaga = function* (action) {
    try {
        const res = yield ProductService.deleteSpecification(action.payload);
        yield put(getSpecificationList());
        yield put(closeSpecificationModal());
        yield put({
            type: deleteSpecificationSuccess.type,
            payload: res.data
        });
    } catch (e) {
        yield put({
            type: deleteSpecificationError.type,
            payload: "Delete action failed"
        });
    }
}

export const fetchSpecificationListWatch = function* () {
    yield takeLatest(getSpecificationList, fetchSpecificationList);
}

export const fetchAddSpecificationWatch = function* () {
    yield takeLatest(addSpecification, fetchAddSpecificationSaga);
}

export const fetchUpdateSpecificationWatch = function* () {
    yield takeLatest(updateSpecification, fetchUpdateSpecificationSaga);
}

export const fetchDeleteSpecificationWatch = function* () {
    yield takeLatest(deleteSpecification, fetchDeleteSpecificationSaga);
}