import axiosInstance from 'service/api';

export default class PartFiltersService {
    static getTemplate() {
        const endPoint = `${window.config.getTemplate}`;
        return axiosInstance.get(endPoint);
    }

    static bulkUpload(values) {
        const endPoint = `${window.config.insBulkPart}`;
        return axiosInstance.post(endPoint, values);
        
    }
}