export const ApiStatus = {
    default: 'default',
    loading: 'loading',
    success: 'success',
    error: 'error'
};

export const PartImagesInfo = [
    'part_thumbnail_url',
    'part_large_image_url',
    'customer_host_url',
    'part_asset_url'
]

const selectTypes = {
    multiSelect: 'multi_select',
    singleSelect: 'single_select'
}
const filterTypes = {
    hierarchy: 'hierarchy',
    specification: 'specification',
    independentFilter:'independentFilter'
}

const itemCount = 5;


const masterLookup = {
    make: {
        name: 'Make',
        sidebar: true,
    },
    model: {
        name: 'Model',
        sidebar: true,
    },
    year: {
        name: 'Year',
        sidebar: true,
    },
    specifications: {
        name: 'specifications',
        sidebar: true,
    },
    independentFilter:{
        name: 'independentFilter',
        sidebar: true,
    },
    classification: {
        name: 'group_classification',
        sidebar: false
    },
    config: {
        name: 'Config',
        sidebar: false
    }
}

export default {
    itemCount,
    masterLookup,
    filterTypes,
    selectTypes
}