
import axiosInstance from 'service/api';
import Constant from 'common/constant';

export default class CategoryService {
    static getInitialCategory() {
        const endPoint = `${window.config.genericGet}?collectionName=${window.config['collections']['masterLookup']}&search={}`;
        return axiosInstance.get(endPoint);
    }
    
    static getAllCategory() {
        const endPoint = `${window.config.genericGet}?collectionName=${window.config['collections']['masterLookup']}&search={'lookup_id':'${Constant.masterLookup.classification.name}'}`;
        return axiosInstance.get(endPoint);
    }
}