import { createSlice } from "@reduxjs/toolkit";
import { ApiStatus } from 'common/constant';

export const initialPartListState = {
    addpartInfo: {
        status: ApiStatus.loading,
        data: ''
    },
    updatepartInfo: {
        status: ApiStatus.loading,
        data: ''
    },
    deletepartInfo: {
        status: ApiStatus.loading,
        data: ''
    },
    destroyEverything: {
        status: ApiStatus.loading,
        data: ''
    },
    addPartModal: {
        isDialogOpen: false,
        mode: '',
        data: null
    },
    addPartImagesModal: {
        isDialogOpen: false,
        mode: '',
        data: null
    },
    partSpecifications: [],
    addSubPartModal: {
        isDialogOpen: false,
        mode: '',
        data: null
    },
    addOEModal: {
        isDialogOpen: false,
        mode: '',
        data: null
    },
    list: [],
    status: '',
    partInfo: {},
    partImages: [],
    subparts: [],
    oem_number: [],
    partFilters:[],
    IndependentPartFilters:[]
};

export const partListSlice = createSlice({
    name: "partListSlice",
    initialState: initialPartListState,
    reducers: {
        getPartList: (state) => {
            state.status = ApiStatus.loading;
        },
        getPartListSuccess: (state, action) => {
            state.list = action.payload;
            state.status = ApiStatus.success;
        },
        getPartListError: (state, action) => {
            state.list = action.payload;
            state.status = ApiStatus.error;
        },
        addPart: (state) => {
            state.addpartInfo.status = ApiStatus.loading;
        },
        addPartSuccess: (state, action) => {
            state.addpartInfo.status = ApiStatus.success;
            state.addpartInfo.data = action.payload;
        },
        addPartError: (state, action) => {
            state.addpartInfo.status = ApiStatus.error;
            state.addpartInfo.data = action.payload;
        },
        updatePart: (state) => {
            state.updatepartInfo.status = ApiStatus.loading;
        },
        updatePartSuccess: (state, action) => {
            state.updatepartInfo.status = ApiStatus.success;
            state.updatepartInfo.data = action.payload;
        },
        updatePartError: (state, action) => {
            state.updatepartInfo.status = ApiStatus.error;
            state.updatepartInfo.data = action.payload;
        },
        deletePart: (state) => {
            state.deletepartInfo.status = ApiStatus.loading;
        },
        deletePartSuccess: (state, action) => {
            state.deletepartInfo.status = ApiStatus.success;
            state.deletepartInfo.data = action.payload;
        },
        deletePartError: (state, action) => {
            state.deletepartInfo.status = ApiStatus.error;
            state.deletepartInfo.data = action.payload;
        },
        resetAddPartStatus: (state, action) => {
            state.addpartInfo.status = ApiStatus.loading;
        },
        resetEverything: (state, action) => {
            state.deletepartInfo.status = ApiStatus.loading;
        },
        updatePartModal: (state, action) => {
            state.addPartModal.isDialogOpen = true;
            state.addPartModal.mode = action.payload.mode;
            state.addPartModal.data = action.payload.data;
        },
        closePartModal: (state, action) => {
            state.addPartModal.isDialogOpen = false;
            state.addPartModal.mode = '';
            state.addPartModal.data = null;
            state.partInfo = {};
            state.partImages = [];
            state.partSpecifications = [];
            state.partFilters = [];
            state.IndependentPartFilters = [];

        },
        updatePartImagesModal: (state, action) => {
            state.addPartImagesModal.isDialogOpen = true;
            state.addPartImagesModal.mode = action.payload.mode;
            state.addPartImagesModal.data = action.payload.data;
        },
        closePartImagesModal: (state, action) => {
            state.addPartImagesModal.isDialogOpen = false;
            state.addPartImagesModal.mode = '';
            state.addPartImagesModal.data = null;
        },
        updateSubPartModal: (state, action) => {
            state.addSubPartModal.isDialogOpen = true;
            state.addSubPartModal.mode = action.payload.mode;
            state.addSubPartModal.data = action.payload.data;
        },
        closeSubPartModal: (state, action) => {
            state.addSubPartModal.isDialogOpen = false;
            state.addSubPartModal.mode = '';
            state.addSubPartModal.data = null;
        },
        updatePartInfo: (state, action) => {
            state.partInfo = action.payload;
        },
        updatePartImages: (state, action) => {
            state.partImages = action.payload;
        },
        updateSubParts: (state, action) => {
            state.subparts = action.payload;
        },
        updatePartSpecifications: (state, action) => {
            state.partSpecifications = action.payload;
        },
        updatepartFilters: (state, action) => {
            state.partFilters = action.payload;
        },
        updateIndependentPartFilters: (state, action) => {
            state.IndependentPartFilters = action.payload;
        },
        updateOEModal: (state, action) => {
            state.addOEModal.isDialogOpen = true;
            state.addOEModal.mode = action.payload.mode;
            state.addOEModal.data = action.payload.data;
        },
        getOEList: (state) => {
            state.status = ApiStatus.loading;
        },
        updateOE: (state, action) => {
            state.oem_number = action.payload;
        },
        closeOEModal: (state, action) => {
            state.addOEModal.isDialogOpen = false;
            state.addOEModal.mode = '';
            state.addOEModal.data = null;
        }
    },
})

export const { getPartList, getPartListSuccess, getPartListError, addPart, addPartSuccess, addPartError, updatePart, updatePartSuccess, updatePartError, deletePart, deletePartSuccess, deletePartError, resetAddPartStatus, updatePartModal, closePartModal, updatePartImagesModal, closePartImagesModal, updatePartInfo, updatePartImages, updateSubParts, updateSubPartModal, closeSubPartModal, updatePartSpecifications, updatepartFilters,
    updateOEModal, getOEList, updateOE, closeOEModal, resetEverything, updateIndependentPartFilters} = partListSlice.actions;

export default partListSlice.reducer;