import { createSlice } from "@reduxjs/toolkit";
import { ApiStatus } from 'common/constant';

export const initialIndependentFilterLabelState = {
    addIndependentFilterLabelInfo:{
        status: ApiStatus.loading,
        data: ''
    },
    updateIndependentFilterLabelInfo:{
        status: ApiStatus.loading,
        data: ''
    },
    deleteIndependentFilterLabelInfo:{
        status: ApiStatus.loading,
        data: {}
    },
    addIndependentFilterLabelModal: {
        isDialogOpen: false,
        mode: '',
        data: null,
        newData:null
    },
    list: { types: [] },
    status: ''
}

export const independentFilterLabelSlice = createSlice({
    name: "IndependentFilterLabelSlice",
    initialState: initialIndependentFilterLabelState,
    reducers: {

        addIndependentFilterLabel:(state) => {
            state.addIndependentFilterLabelInfo.status = ApiStatus.loading;
        },
        addIndependentFilterLabelSuccess:(state, action) => {
            state.addIndependentFilterLabelInfo.status = ApiStatus.success;
            state.addIndependentFilterLabelInfo.data = action.payload;
        },
        addIndependentFilterLabelError:(state, action) => {
            state.addIndependentFilterLabelInfo.status = ApiStatus.error;
            state.addIndependentFilterLabelInfo.data = action.payload;
        },
        updateIndependentFilterLabel:(state) => {
            state.updateIndependentFilterLabelInfo.status = ApiStatus.loading;
        },
        updateIndependentFilterLabelSuccess:(state, action) => {
            state.updateIndependentFilterLabelInfo.status = ApiStatus.success;
            state.updateIndependentFilterLabelInfo.data = action.payload;
        },
        updateIndependentFilterLabelError:(state, action) => {
            state.updateIndependentFilterLabelInfo.status = ApiStatus.error;
            state.updateIndependentFilterLabelInfo.data = action.payload;
        },
        deleteIndependentFilterLabel:(state,action) => {
            state.deleteIndependentFilterLabelInfo.status = ApiStatus.loading;
            state.deleteIndependentFilterLabelInfo.data = action.payload;
        },
        deleteIndependentFilterLabelSuccess:(state, action) => {
            state.deleteIndependentFilterLabelInfo.status = ApiStatus.success;
            state.deleteIndependentFilterLabelInfo.data = action.payload;
        },
        deleteIndependentFilterLabelError:(state, action) => {
            state.deleteIndependentFilterLabelInfo.status = ApiStatus.error;
            state.deleteIndependentFilterLabelInfo.data = action.payload;
        },
        resetIndependentFilterLabelStatus:(state, action) => {
            state.addIndependentFilterLabelInfo.status = ApiStatus.loading;
            state.updateIndependentFilterLabelInfo.status = ApiStatus.loading;
            state.deleteIndependentFilterLabelInfo.status = ApiStatus.loading;
        },
        closeIndependentFilterLabelModal: (state, action) => {
            state.addIndependentFilterLabelModal.isDialogOpen = false;
            state.addIndependentFilterLabelModal.mode = '';
            state.addIndependentFilterLabelModal.data = null;
        },
       
    }
})

export const { 
    addIndependentFilterLabel,addIndependentFilterLabelSuccess,addIndependentFilterLabelError,
    updateIndependentFilterLabel,updateIndependentFilterLabelSuccess,updateIndependentFilterLabelError,
    deleteIndependentFilterLabel, deleteIndependentFilterLabelSuccess, deleteIndependentFilterLabelError, closeIndependentFilterLabelModal
} = independentFilterLabelSlice.actions;

export default independentFilterLabelSlice.reducer;