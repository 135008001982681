import { takeEvery, put } from '@redux-saga/core/effects';
import PartDetailsService from 'service/partDetails.service';
import { getPartList , getPartListSuccess , getPartListError , addPart , addPartSuccess , addPartError , updatePart , updatePartSuccess , 
    updatePartError , deletePart , deletePartSuccess , deletePartError , resetAddPartStatus , updatePartModal , closePartModal } from './partDetailsList.slice';

export const fetchAddPartSaga = function* (action) {
    try {
        const res = yield PartDetailsService.addPart(action.payload);
        yield put(getPartList());
        yield put(closePartModal());
        yield put({
            type: addPartSuccess.type,
            payload: res.data
        });
    } catch (e) {
        yield put({
            type: addPartError.type,
            payload: "Add part action failed"
        });
    }
}

export const fetchUpdatePartSaga = function* (action) {
    try {
        const res = yield PartDetailsService.updatePart(action.payload);
        yield put(getPartList());
        yield put(closePartModal());
        yield put({
            type: updatePartSuccess.type,
            payload: res.data
        });
    } catch (e) {
        yield put({
            type: updatePartError.type,
            payload: "Update part action failed"
        });
    }
}

export const fetchDeletePartSaga = function* (action) {
    try {
        const res = yield PartDetailsService.deletePart(action.payload);
        yield put(getPartList());
        yield put(closePartModal());
        yield put({
            type: deletePartSuccess.type,
            payload: res.data
        });
    } catch (e) {
        yield put({
            type: deletePartError.type,
            payload: "Delete action failed"
        });
    }
}

export const fetchPartListSaga = function* () {
    try {
        const res = yield PartDetailsService.getPartList();
        yield put({
            type: getPartListSuccess.type,
            payload: res.data
        });
    } catch (e) {
        yield put({
            type: getPartListError.type,
            payload: "Partlist failed to fetch"
        });
    }
}

export const fetchAddPartWatch = function* () {
    yield takeEvery(addPart, fetchAddPartSaga);
}

export const fetchUpdatePartWatch = function* () {
    yield takeEvery(updatePart, fetchUpdatePartSaga);
}

export const fetchDeletePartWatch = function* () {
    yield takeEvery(deletePart, fetchDeletePartSaga);
}

export const fetchPartListWatch = function* () {
    yield takeEvery(getPartList, fetchPartListSaga);
}