import { takeEvery, put } from '@redux-saga/core/effects';
import CategoriesService from 'service/groupList.service';
import { 
    getGroupList , getGroupListSuccess ,getGroupListError,
    addGroup,addGroupSuccess,addGroupError,
    updateGroup,updateGroupSuccess,updateGroupError,
    deleteGroup,deleteGroupSuccess,deleteGroupError,
    closeGroupModal,
    addFilterLabel, addFilterLabelSuccess, addFilterLabelError,
    updateFilterLabel, updateFilterLabelSuccess, updateFilterLabelError,
    deleteFilterLabel, deleteFilterLabelSuccess, deleteFilterLabelError

} from './groupList.slice';

export const fetchAddGroupSaga = function* (action) {
    try {
        const res = yield CategoriesService.addGroup(action.payload);
        yield put(getGroupList());
        yield put(closeGroupModal());
        yield put({
            type: addGroupSuccess.type,
            payload: res.data
        });
    } catch (e) {
        yield put({
            type: addGroupError.type,
            payload: "Add Group action failed"
        });
    }
}

export const fetchUpdateGroupSaga = function* (action) {
    try {
        const res = yield CategoriesService.updateGroup(action.payload);
        yield put(getGroupList());
        yield put(closeGroupModal());
        yield put({
            type: updateGroupSuccess.type,
            payload: res.data
        });
    } catch (e) {
        yield put({
            type: updateGroupError.type,
            payload: "Update Group action failed"
        });
    }
}

export const fetchDeleteGroupSaga = function* (action) {
    try {
        const res = yield CategoriesService.deleteGroup(action.payload);
        yield put(getGroupList());
        yield put(closeGroupModal());
        yield put({
            type: deleteGroupSuccess.type,
            payload: res.data
        });
    } catch (e) {
        yield put({
            type: deleteGroupError.type,
            payload: "Delete action failed"
        });
    }
}

// etx

export const fetchAddFilterLabelSaga = function* (action) {
    try {
        const res = yield CategoriesService.addFilterLabel(action.payload);
        yield put(closeGroupModal());
        yield put({
            type: addFilterLabelSuccess.type,
            payload: res.data
        });
    } catch (e) {
        yield put({
            type: addFilterLabelError.type,
            payload: "Add Filter action failed"
        });
    }
}

export const fetchUpdateFilterLabelSaga = function* (action) {
    try {
        const res = yield CategoriesService.updateFilterLabel(action.payload);
        yield put(closeGroupModal());
        yield put({
            type: updateFilterLabelSuccess.type,
            payload: res.data
        });
    } catch (e) {
        yield put({
            type: updateFilterLabelError.type,
            payload: "Update Filter action failed"
        });
    }
}

export const fetchDeleteFilterLabelSaga = function* (action) {
    try {
        const res = yield CategoriesService.deleteFilterLabel(action.payload);
        yield put(closeGroupModal());
        yield put({
            type: deleteFilterLabelSuccess.type,
            payload: res.data
        });
    } catch (e) {
        yield put({
            type: deleteFilterLabelError.type,
            payload: "Delete Filter failed"
        });
    }
}

// end

export const fetchGroupListSaga = function* () {
    try {
        const res = yield CategoriesService.getGroupList();
        yield put({
            type: getGroupListSuccess.type,
            payload: res.data
        });
    } catch (e) {
        yield put({
            type: getGroupListError.type,
            payload: "Grouplist failed to fetch"
        });
    }
}

export const fetchAddGroupWatch = function* () {
    yield takeEvery(addGroup, fetchAddGroupSaga);
}

export const fetchUpdateGroupWatch = function* () {
    yield takeEvery(updateGroup, fetchUpdateGroupSaga);
}

export const fetchDeleteGroupWatch = function* () {
    yield takeEvery(deleteGroup, fetchDeleteGroupSaga);
}

// etc

export const fetchAddFilterLabelWatch = function* () {
    yield takeEvery(addFilterLabel, fetchAddFilterLabelSaga);
}

export const fetchUpdateFilterLabelWatch = function* () {
    yield takeEvery(updateFilterLabel, fetchUpdateFilterLabelSaga);
}

export const fetchDeleteFilterLabelWatch = function* () {
    yield takeEvery(deleteFilterLabel, fetchDeleteFilterLabelSaga);
}

// end

export const fetchGroupListWatch = function* () {
    yield takeEvery(getGroupList, fetchGroupListSaga);
}