import { createSlice } from "@reduxjs/toolkit";
import { ApiStatus } from 'common/constant';

export const initialPartState = {
    provider: [],
    status: '',
    postPartData:[]
};

export const partSlice = createSlice({
    name: "partSlice",
    initialState: initialPartState,
    reducers: {
        getProvider: (state) => {
            state.status = ApiStatus.loading;
        },
        getProviderSuccess: (state, action) => {
            state.provider = action.payload;
            state.status = ApiStatus.success;
        },
        getProviderError: (state, action) => {
            state.provider = action.payload;
            state.status = ApiStatus.error;
        },
        postPartData: (state, action) => {
            state.postPartData = action.payload;
        },
    },
})

export const { getProvider, getProviderSuccess, getProviderError, postPartData } = partSlice.actions;

export default partSlice.reducer;