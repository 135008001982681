import { createSlice } from "@reduxjs/toolkit";
import { ApiStatus } from 'common/constant';

export const initialPartFitlerState = {
    addPartFilterModal: {
        isDialogOpen: false,
        mode: '',
        data: null
    },
    list: [],
    status: ''
  };

export const partFilterListSlice = createSlice({
    name: "partFilterListSlice",
    initialState: initialPartFitlerState,
    reducers:{
        getPartFilterList: (state) => {
            state.status = ApiStatus.loading;
        },
        getPartFilterListSuccess: (state, action) => {
            state.list = action.payload;
            state.status = ApiStatus.success;
        },
        getPartFilterListError: (state, action) => {
            state.list = action.payload;
            state.status = ApiStatus.error;
        },
        updatePartFilterModal:(state, action) => {
            state.addPartFilterModal.isDialogOpen = true;
            state.addPartFilterModal.mode = action.payload.mode;
            state.addPartFilterModal.data = action.payload.data;
        },
        closePartFilterModal:(state, action) => {
            state.addPartFilterModal.isDialogOpen = false;
            state.addPartFilterModal.mode = '';
            state.addPartFilterModal.data = null;
        },
    },
})

export const { getPartFilterList , getPartFilterListSuccess , getPartFilterListError , updatePartFilterModal , closePartFilterModal} = partFilterListSlice.actions;

export default partFilterListSlice.reducer;