import { createSlice } from '@reduxjs/toolkit';
import { ApiStatus } from 'common/constant';

export const initialCategoryState = {
    initialCategory: {},
    allCategory: {},
    selectedFilters: [],
    selectedSpecificationFilters: [],
    selectedIndependentFilters:[],
    status: ''
};

export const categorySlice = createSlice({
  name: 'categorySlice',
  initialState: initialCategoryState,
  reducers: {
    getInitialCategory: (state) => {
      state.status = ApiStatus.loading;
      state.initialCategory= [];
      state.allCategory= {};
    },
    getInitialCategorySuccess: (state, action) => {
        state.status = ApiStatus.success;
        state.initialCategory = action.payload;
    },
    getInitialCategoryError: (state, action) => {
        state.status = ApiStatus.error;
        state.initialCategory = action.payload;
    },
    getAllCategory: (state) => {
      state.status = ApiStatus.loading;
    },
    getAllCategorySuccess: (state, action) => {
        state.status = ApiStatus.success;
        state.allCategory = action.payload.length ? action.payload[0]: action.payload;
    },
    getAllCategoryError: (state, action) => {
        state.status = ApiStatus.error;
        state.allCategory = action.payload;
    },
    updateselectedFilters: (state, { payload }) => {
      state.selectedFilters = payload;
    },
    updateselectedIndependentFilters: (state, { payload }) => {
      state.selectedIndependentFilters = payload;
    },
    updateSelectedSpecificationFilters: (state, { payload }) => {
      state.selectedSpecificationFilters = payload;
    }
  }
});

export const {
  getInitialCategory,
  getInitialCategorySuccess,
  getInitialCategoryError,
  getAllCategory,
  getAllCategorySuccess,
  getAllCategoryError,
  updateselectedFilters,
  updateSelectedSpecificationFilters,
  updateselectedIndependentFilters
} = categorySlice.actions;

export default categorySlice.reducer;
