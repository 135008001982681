import axiosInstance from 'service/api';

export default class AdminService {
    static getUserList() {
        const endPoint = `${window.config.genericGet}?collectionName=${window.config.user}&search={}`;
        return axiosInstance.get(endPoint);
    }
    static addUser(values) {
        const body = {
            collectionName: 'users',
            data: [values]
        }
        const endPoint = `${window.config.insertDocument}`;
        return axiosInstance.post(endPoint, body);
    }
    static updateUser(values) {
        const params = {...values};
        delete params._id;
        const body = {
            collectionName: 'users',
            data: [params],
            condition: [{_id: values._id}]
        }
        const endPoint = `${window.config.updateDocument}`;
        return axiosInstance.post(endPoint, body);
    }
    static deleteUser(values) {
        const body = {
            collectionName: 'users',
            delete: [{_id: values._id}],
            
        }
        const endPoint = `${window.config.deleteDocument}`;
        return axiosInstance.post(endPoint, body);
    }
}