import axiosInstance from 'service/api';

export default class AdminService {
    static adminresetpassword(values){
        const endPoint = window.config.ResetPassword;
        return axiosInstance.post(endPoint, values);
    }
    static adminforgotpassword(values){
        console.log(values)
        const endPoint = window.config.ForgotPassword;
        return axiosInstance.post(endPoint, values);
    }
}