import axiosInstance from 'service/api';

export default class CatalogueSettingService {
    static cataloguesettings(values){
        const params = {...values};
        delete params._id;
        delete params.created_by;
        delete params.creation_date;
        delete params.modified_by;  
        delete params.updated_date;
        const body = {
            collectionName: 'organization',
            data: [params],
            condition: [{_id: values._id}]
        }
        const endPoint = `${window.config.updateDocument}`;
        return axiosInstance.post(endPoint, body);
    }
    static filterlabel(){
        const endPoint = `${window.config.genericGet}?collectionName=${window.config.collections['masterLookup']}&search={}`;
        return axiosInstance.get(endPoint);
    }
}